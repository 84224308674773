(function () {
  'use strict';

  angular
    .module('module.navigation')
    .controller('controller.navigation', [
      '$scope',
      '$rootScope',
      'NavigationService',
      'AuthenticationService',
      controller,
    ]);

  /**
   * @param $scope
   * @param NavigationService
   * @param AuthenticationService
   */
  function controller($scope, $rootScope, NavigationService, AuthenticationService) {
    $scope.navigation = NavigationService.getMainNavItems();
    $scope.dropdownSettings = NavigationService.getSettingsDropdownItems();
    $scope.dropdownProfile = NavigationService.getProfileDropdownItems();
    $scope.dropdownMarketing = NavigationService.getMarketingDropdownItems();
    $scope.showHelp = AuthenticationService.getPermissions().companyType !== 'owner';

    $rootScope.showNav = $rootScope.showNav || false;

    AuthenticationService.getUser().then(function (user) {
      if (user.permissions.companyType === 'retailer') {
        if (
          (user.metadata.mustAcceptUpdatedTermsAndConditions ||
            user.metadata.mustAcceptTermsAndConditions) &&
          !$rootScope.showNav
        ) {
          $scope.showNav = false;
        }
      } else {
        $scope.showNav = true;
      }

      if (
        !user.metadata.mustAcceptUpdatedTermsAndConditions &&
        !user.metadata.mustAcceptTermsAndConditions
      ) {
        $scope.showNav = true;
      }

      if ($rootScope.showNav) {
        $scope.showNav = true;
      }
    });

    $scope.signOut = function () {
      $rootScope.showNav = false;
      AuthenticationService.signOut();
    };
  }
})();
