(function () {
  'use strict';

  angular.module('module.sales').component('ttFinaliseModalQuoteSettings', {
    templateUrl: 'templates/sales.quotes.finalise.modal.quoteSettings.component.template.html',
    controller: ['QuoteFinalisationService', 'CurrencyService', 'AddressService', controller],
    bindings: {
      quote: '<',
      onContinue: '<',
      onCancel: '&',
    },
  });

  function controller(QuoteFinalisationService, CurrencyService, AddressService) {
    /* jshint validthis: true */
    var vm = this;

    vm.$onInit = onInit;
    vm.save = save;
    CurrencyService.getSettingsAsync().then((data) => {
      vm.salesTaxType = data.salesTaxType;
    });

    function onInit() {
      QuoteFinalisationService.getFinalisationSettings(vm.quote).then(function (settings) {
        vm.settings = settings;
      });

      vm.hasAddress = !AddressService.addressEmpty(vm.quote.metadata.customer.address);
    }

    function save() {
      vm.onContinue(vm.settings);
    }
  }
})();
