(function () {
  'use strict';

  angular
    .module('module.customItem')
    .filter('pricingUnitSuffix', ['PRICING_UNITS', pricingUnitSuffix]);
  angular
    .module('module.customItem')
    .filter('pricingUnitLabel', ['PRICING_UNITS', pricingUnitLabel]);

  function pricingUnitSuffix(PRICING_UNITS) {
    return function (pricingUnit) {
      if (typeof pricingUnit === 'undefined' || pricingUnit === '') {
        return '';
      }

      return findUnit(pricingUnit, PRICING_UNITS).suffix;
    };
  }

  function pricingUnitLabel(PRICING_UNITS) {
    return function (pricingUnit) {
      if (typeof pricingUnit === 'undefined' || pricingUnit === '') {
        return '';
      }

      return findUnit(pricingUnit, PRICING_UNITS).label;
    };
  }

  function findUnit(pricingUnit, PRICING_UNITS) {
    const unit = PRICING_UNITS.find((unit) => unit.key === pricingUnit);
    if (typeof unit === 'undefined') {
      throw new Error('Pricing unit not found for key: ' + pricingUnit);
    }

    return unit;
  }
})();
