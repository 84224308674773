(function () {
  'use strict';

  angular.module('module.sales').directive('ttPricingRequestSlice', function () {
    return {
      templateUrl: 'templates/sales.quotes.pricingRequest.slice.directive.template.html',
      restrict: 'A',
      controllerAs: '$ctrl',
      scope: {
        pricingRequest: '<',
        status: '<',
        currency: '<',
      },
      controller: ['Notification', controller],
      bindToController: true,
    };
  });

  function controller(Notification) {
    /* jshint validthis: true */
    var vm = this;

    vm.copyLinkAction = copyLinkAction;

    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
