(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.orders.orderPart', [
      '$scope',
      '$rootScope',
      'OrderPartApiService',
      'CurrencyService',
      '$q',
      '$timeout',
      'dataOrderPart',
      controller,
    ]);

  function controller(
    $scope,
    $rootScope,
    OrderPartApiService,
    CurrencyService,
    $q,
    $timeout,
    dataOrderPart,
  ) {
    /* jshint validthis: true */
    var vm = this;
    var updateOrderPartTimeoutPromise;

    $rootScope.bodyClass = 'page__order';
    $scope.$on('$destroy', function () {
      cancelOrderPartUpdateTimeout();
      $rootScope.bodyClass = '';
    });

    vm.accept = accept;
    vm.dispatch = dispatch;

    vm.orderPart = dataOrderPart;
    $scope.orderReference = vm.orderPart.reference;
    updateOrderPart(dataOrderPart);

    CurrencyService.getSettingsAsync().then((data) => {
      vm.salesTaxType = data.salesTaxType;
      vm.currencySymbol = data.currencySymbol;
    });

    function accept() {
      OrderPartApiService.accept(vm.orderPart).then(function (orderPart) {
        updateOrderPart(orderPart);
      });
    }

    function dispatch() {
      OrderPartApiService.dispatch(vm.orderPart).then(function (orderPart) {
        updateOrderPart(orderPart);
      });
    }

    function updateOrderPart(orderPart) {
      cancelOrderPartUpdateTimeout();
      var orderPartPromise = orderPart
        ? $q.when(orderPart)
        : OrderPartApiService.get(vm.orderPart.id);

      orderPartPromise.then(function (orderPart) {
        if (orderPart.generatingDownloadData) {
          updateOrderPartTimeoutPromise = $timeout(updateOrderPart, 2000);

          return;
        }

        vm.orderPart = orderPart;
      });
    }

    function cancelOrderPartUpdateTimeout() {
      $timeout.cancel(updateOrderPartTimeoutPromise);
    }
  }
})();
