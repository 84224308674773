(function () {
  'use strict';

  angular
    .module('module.settings.partsShop')
    .controller('controller.settings.partsShop.createUpdate', [
      '$state',
      'PartsShopApiService',
      'CurrencyService',
      'PricingUnitsService',
      'CUSTOM_ITEM_CATEGORY_ORDER',
      'dataItem',
      controller,
    ]);

  function controller(
    $state,
    PartsShopApiService,
    CurrencyService,
    PricingUnitsService,
    CUSTOM_ITEM_CATEGORY_ORDER,
    dataItem,
  ) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.save = save;
    $ctrl.resetImage = resetImage;

    $ctrl.saving = false;

    $ctrl.categories = CUSTOM_ITEM_CATEGORY_ORDER;

    $ctrl.image = null;
    CurrencyService.getSettingsAsync().then((data) => {
      $ctrl.currency = data;
    });

    const newItem = dataItem === null;
    if (newItem) {
      $ctrl.item = {
        costPrice: 0,
        sellPrice: 0,
        pricingUnit: 'each',
        discountable: true,
        showAsDedicatedPage: false,
      };
    } else {
      $ctrl.item = dataItem;
    }

    PricingUnitsService.getFilteredUnits($ctrl.item.pricingUnit).then(
      (units) => ($ctrl.pricingUnits = units),
    );

    function save() {
      $ctrl.saving = true;

      const action = newItem
        ? PartsShopApiService.create($ctrl.item, $ctrl.image)
        : PartsShopApiService.update(dataItem.id, $ctrl.item, $ctrl.image);

      action.then(
        () => $state.go('settings.partsShop', {}, {reload: true}),
        () => ($ctrl.saving = false),
      );
    }

    function resetImage() {
      $ctrl.image = null;
      $ctrl.item.imageId = null;
    }
  }
})();
