(function () {
  'use strict';

  angular.module('module.sales').component('ttDocumentsList', {
    templateUrl: 'templates/sales.quotes.documents.list.component.template.html',
    controller: [
      '$scope',
      'PricingRequestApiService',
      'ContractReportApiService',
      'SettingsService',
      'QuoteFinalisationService',
      'Notification',
      controller,
    ],
    bindings: {
      quote: '<',
    },
  });

  function controller(
    $scope,
    PricingRequestApiService,
    ContractReportApiService,
    SettingsService,
    QuoteFinalisationService,
    Notification,
  ) {
    /* jshint validthis: true */
    var vm = this;

    vm.pricingRequests = [];
    vm.$onInit = onInit;

    function onInit() {
      PricingRequestApiService.pollList(vm.quote, $scope).then(
        function (pricingRequests) {
          vm.pricingRequests = pricingRequests;
        },
        null,
        function (pricingRequests) {
          vm.pricingRequests = pricingRequests;
        },
      );

      ContractReportApiService.pollUntilGenerated(vm.quote, $scope).then(function (contractReport) {
        if (contractReport) {
          vm.contractReport = contractReport;
        }
      });

      SettingsService.getSettings().then(function (settings) {
        vm.retailerActive = settings.metadata.active;
        vm.currency = settings.settings.currency.currency;
      });

      vm.contractReport = vm.quote.metadata.documents.contractReport;
      vm.customerDetailsComplete = vm.quote.metadata.customer.active;
    }

    $scope.$watch(
      '$ctrl.quote.metadata',
      function () {
        vm.cannotFinalise = cannotFinalise();
      },
      true,
    );

    function cannotFinalise() {
      if (vm.quote.metadata.status === 'expired') {
        return true;
      }

      return (
        !QuoteFinalisationService.isFinalised(vm.quote) &&
        !QuoteFinalisationService.isProcessing(vm.quote) &&
        !vm.quote.metadata.allowFinalise
      );
    }

    vm.copyLinkAction = copyLinkAction;
    function copyLinkAction() {
      Notification.success('Link copied to clipboard');
    }
  }
})();
