(function () {
  'use strict';

  angular
    .module('module.termsAndConditions')
    .controller('controller.termsAndConditions.list', [
      '$window',
      '$state',
      'TermsAndConditionsService',
      controller,
    ]);

  function controller($window, $state, TermsAndConditionsService) {
    /* jshint validthis: true */
    var termsVm = this;

    init();

    function init() {
      TermsAndConditionsService.listTermsandconditions().then(function (terms) {
        termsVm.termsList = terms;
        termsVm.selectedTerm = terms.find((term) => term.currentVersion);
      });
    }

    termsVm.viewTermsConditions = function (termList) {
      if (termList.id) {
        $state.go('termsAndConditions.update', {id: termList.id});
      } else {
      }
    };

    termsVm.openModal = function (termList) {
      termsVm.selectedTerm = termList;
      $('#termDetailsModal').modal('show');
    };

    termsVm.delete = function (term) {
      if ($window.confirm('Are you sure you want to delete this document?')) {
        TermsAndConditionsService.deleteTermsConditions(term.id).then(function () {
          init();
        });
      }
    };

    termsVm.makeCurrentTerms = function () {
      if ($window.confirm('Are you sure you want to make this the current Terms and Conditions?')) {
        if (!termsVm.selectedTerm.currentVersion) {
          termsVm.selectedTerm.currentVersion = true;
          var updatePayload = {
            termsAndConditionsId: termsVm.selectedTerm.id,
            currentVersion: termsVm.selectedTerm.currentVersion,
          };

          TermsAndConditionsService.updateTermsandconditions(updatePayload).then(function () {
            init();
          });
        }
      }
    };
  }
})();
