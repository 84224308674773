(function () {
  'use strict';

  angular.module('module.termsAndConditions', [
    'module.api',
    'ui.router',
    'angular-pca',
    'ng-sortable',
  ]);
})();
