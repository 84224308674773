(function () {
  'use strict';

  angular
    .module('module.dashboard')
    .controller('controller.dashboard.terms', [
      '$rootScope',
      '$state',
      'TermsAndConditionsService',
      controller,
    ]);

  function controller($rootScope, $state, TermsAndConditionsService) {
    /* jshint validthis: true */
    var vm = this;
    $rootScope.bodyClass = 'termsAndConditions';
    init();

    function init() {
      TermsAndConditionsService.getCurrentVersion().then(function (terms) {
        vm.termsList = terms;
      });
    }

    vm.update = function (id) {
      if (id) {
        var data = {termsAndConditionsId: id};
        TermsAndConditionsService.postTermsandconditions(data).then(function () {
          $rootScope.showNav = true;
          return $state.go('sales.customers.list');
        });
      }
    };
  }
})();
