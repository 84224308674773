(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .controller('controller.settings.preferences.localisation', [
      '$scope',
      'CurrencyApiService',
      'dataSettings',
      controller,
    ]);

  function controller($scope, CurrencyApiService, dataSettings) {
    /* jshint validthis: true */
    var vm = this;

    vm.update = update;
    vm.settings = dataSettings.settings.currency;
    vm.saving = false;
    vm.showModal = false;

    let originalSettings = {
      salesTaxRate: vm.settings.salesTaxRate,
      measurementType: vm.settings.measurementType,
      dimensionsUnit: vm.settings.dimensionsUnit,
      fractionalPrecision: vm.settings.fractionalPrecision,
      hingeAnnotationDirection: vm.settings.hingeAnnotationDirection,
    };

    vm.checkUpdatedFields = checkUpdatedFields;

    if (dataSettings.metadata.locale === 'UK') {
      vm.hideImperial = true;
    }

    vm.timezone = vm.settings.timezone;

    vm.timezones = Intl.supportedValuesOf('timeZone');

    function checkUpdatedFields() {
      if (
        originalSettings.salesTaxRate !== vm.settings.salesTaxRate ||
        originalSettings.measurementType !== vm.settings.measurementType ||
        originalSettings.dimensionsUnit !== vm.settings.dimensionsUnit ||
        originalSettings.fractionalPrecision !== vm.settings.fractionalPrecision ||
        originalSettings.hingeAnnotationDirection !== vm.settings.hingeAnnotationDirection
      ) {
        vm.showModal = true;
      } else {
        vm.showModal = false;
      }
    }

    function update() {
      vm.saving = true;
      $scope.form.$setPristine();
      CurrencyApiService.update(vm.settings).finally(function () {
        originalSettings = {
          salesTaxRate: vm.settings.salesTaxRate,
          measurementType: vm.settings.measurementType,
          dimensionsUnit: vm.settings.dimensionsUnit,
          fractionalPrecision: vm.settings.fractionalPrecision,
        };
        vm.showModal = false;
        vm.saving = false;
      });
    }
  }
})();
