(function () {
  'use strict';

  const module = angular.module('module.customItem');

  module.constant('PRICING_UNITS', [
    {key: 'each', label: 'Each', suffix: '', type: null},
    {key: 'per_m', label: 'Per metre', suffix: 'm', type: 'metric'},
    {key: 'per_m2', label: 'Per square metre', suffix: 'm²', type: 'metric'},
    {key: 'per_l', label: 'Per litre', suffix: 'L', type: 'metric'},
    {key: 'per_kg', label: 'Per kilo', suffix: 'kg', type: 'metric'},
    {key: 'per_ft', label: 'Per foot', suffix: 'ft', type: 'imperial'},
    {key: 'per_ft2', label: 'Per square foot', suffix: 'sq ft', type: 'imperial'},
    {key: 'per_fl_oz', label: 'Per fluid ounce', suffix: 'fl oz', type: 'imperial'},
    {key: 'per_gal', label: 'Per gallon', suffix: 'gal', type: 'imperial'},
    {key: 'per_lb', label: 'Per pound', suffix: 'lb', type: 'imperial'},
  ]);

  const CUSTOM_ITEM_CATEGORY = {
    ACCESS_EQUIPMENT: 'Access Equipment',
    BUILDING_WORKS_MATERIALS: 'Building Works & Materials',
    COMPLIANCE: 'Compliance',
    DELIVERY: 'Delivery',
    GLASS_GLAZING: 'Glass & Glazing',
    MASTICS_FIXINGS_ANCILLARIES: 'Mastics, Fixings, Ancillaries',
    PRODUCTS: 'Products',
    SERVICES: 'Services',
    TRIMS_WINDOW_BOARDS: 'Trims & Window Boards',
    WASTE_DISPOSAL: 'Waste Disposal',
    MISC: 'Misc',
  };
  module.constant('CUSTOM_ITEM_CATEGORY', CUSTOM_ITEM_CATEGORY);

  module.constant('CUSTOM_ITEM_CATEGORY_ORDER', [
    CUSTOM_ITEM_CATEGORY.ACCESS_EQUIPMENT,
    CUSTOM_ITEM_CATEGORY.BUILDING_WORKS_MATERIALS,
    CUSTOM_ITEM_CATEGORY.COMPLIANCE,
    CUSTOM_ITEM_CATEGORY.DELIVERY,
    CUSTOM_ITEM_CATEGORY.GLASS_GLAZING,
    CUSTOM_ITEM_CATEGORY.MASTICS_FIXINGS_ANCILLARIES,
    CUSTOM_ITEM_CATEGORY.PRODUCTS,
    CUSTOM_ITEM_CATEGORY.SERVICES,
    CUSTOM_ITEM_CATEGORY.TRIMS_WINDOW_BOARDS,
    CUSTOM_ITEM_CATEGORY.WASTE_DISPOSAL,
    CUSTOM_ITEM_CATEGORY.MISC,
  ]);
})();
