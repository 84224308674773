(function () {
  'use strict';

  angular.module('module.retailers').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('retailers', {
      abstract: true,
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        skip: true,
      },
    });

    $stateProvider.state('retailers.owner', {
      url: '/owner',
      abstract: true,
      ncyBreadcrumb: {
        skip: true,
      },
    });

    $stateProvider.state('retailers.owner.list', {
      url: '/retailers',
      views: {
        'content@': {
          controller: 'controller.retailers.ownerList',
          controllerAs: '$ctrl',
          templateUrl: 'templates/retailers.ownerList.template.html',
          resolve: {
            dataRetailers: [
              'RetailersService',
              function (RetailersService) {
                return RetailersService.listRetailers(1);
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Retailers',
      },
      data: {
        meta: {
          titleSuffix: '| Retailers',
        },
      },
    });

    $stateProvider.state('retailers.owner.view', {
      url: '/retailers/:retailerId',
      abstract: true,
      views: {
        'content@': {
          templateUrl: 'templates/retailers.ownerView.template.html',
        },
      },
      ncyBreadcrumb: {
        parent: 'retailers.owner.list',
        label: '{{ $ctrl.retailer.metadata.name }}',
      },
    });

    $stateProvider.state('retailers.owner.view.details', {
      url: '/details',
      controller: 'controller.retailers.ownerView.details',
      controllerAs: '$ctrl',
      templateUrl: 'templates/retailers.ownerView.details.template.html',
      resolve: {
        dataRetailer: [
          '$stateParams',
          'RetailersService',
          function ($stateParams, RetailersService) {
            return RetailersService.retrieveRetailerForOwner($stateParams.retailerId);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Details',
      },
      data: {
        meta: {
          titleSuffix: 'Retailers | {{ $ctrl.retailer.metadata.name }} | Details',
        },
      },
    });

    $stateProvider.state('retailers.owner.view.users', {
      url: '/users',
      controller: 'controller.retailers.ownerView.users',
      controllerAs: '$ctrl',
      templateUrl: 'templates/retailers.ownerView.users.template.html',
      resolve: {
        dataRetailer: [
          '$stateParams',
          'RetailersService',
          function ($stateParams, RetailersService) {
            return RetailersService.retrieveRetailer($stateParams.retailerId);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Users',
      },
      data: {
        meta: {
          titleSuffix: 'Retailers | {{ $ctrl.retailer.metadata.name }} | Users',
        },
      },
    });

    $stateProvider.state('retailers.owner.view.retailerProducts', {
      url: '/retailer-products',
      controller: 'controller.retailers.ownerView.retailerProducts',
      controllerAs: '$ctrl',
      templateUrl: 'templates/retailers.ownerView.retailerProducts.template.html',
      resolve: {
        dataRetailer: [
          '$stateParams',
          'RetailersService',
          function ($stateParams, RetailersService) {
            return RetailersService.retrieveRetailer($stateParams.retailerId);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Retailer Products',
      },
      data: {
        meta: {
          titleSuffix: 'Retailers | {{ $ctrl.retailer.metadata.name }} | Retailer Products',
        },
      },
    });

    $stateProvider.state('retailers.owner.view.partsShop', {
      url: '/parts-shop',
      controller: 'controller.retailers.ownerView.partsShopProducts',
      controllerAs: '$ctrl',
      templateUrl: 'templates/retailers.ownerView.partsShopProducts.template.html',
      resolve: {
        dataRetailer: [
          '$stateParams',
          'RetailersService',
          function ($stateParams, RetailersService) {
            return RetailersService.retrieveRetailer($stateParams.retailerId);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Part Shop Items',
      },
      data: {
        meta: {
          titleSuffix: 'Retailers | {{ $ctrl.retailer.metadata.name }} | Part Shop Items',
        },
      },
    });

    $stateProvider.state('retailers.owner.view.termsConditions', {
      url: '/terms-and-conditions',
      controller: 'controller.retailers.ownerView.termsConditions',
      controllerAs: '$ctrl',
      templateUrl: 'templates/retailers.ownerView.termsConditions.template.html',
      resolve: {
        dataRetailer: [
          '$stateParams',
          'RetailersService',
          function ($stateParams, RetailersService) {
            return RetailersService.retrieveRetailer($stateParams.retailerId);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Terms And Conditions',
      },
      data: {
        meta: {
          titleSuffix: 'Retailers | {{ $ctrl.retailer.metadata.name }} | terms-and-conditions',
        },
      },
    });
  }
})();
