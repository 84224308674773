(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .controller('controller.retailerProducts.glazingPricing.modal', [
      '$q',
      'glazingForRange',
      'allGlazing',
      'rangeName',
      'componentPricing',
      'close',
      'CurrencyService',
      controller,
    ]);

  function controller(
    $q,
    glazingForRange,
    allGlazing,
    rangeName,
    componentPricing,
    close,
    CurrencyService,
  ) {
    /* jshint validthis: true */
    var $ctrl = this;
    $ctrl.glazingPieces = glazingForRange;
    $ctrl.rangeName = rangeName;
    $ctrl.allGlazing = allGlazing;
    $ctrl.close = close;
    $ctrl.apply = apply;
    $ctrl.toggleOtherGlazingStyles = toggleOtherGlazingStyles;
    $ctrl.applyToOtherRanges = applyToOtherRanges;
    CurrencyService.getSettingsAsync().then((data) => {
      $ctrl.currencySymbol = data.currencySymbol;
    });

    $ctrl.glazingLabels = {
      '1a': '146mm x 80mm',
      '1b': '149mm x 302mm',
      '1c': '66mm x 66mm',
      '1d': '134mm x 410mm',
      '1e': '130mm x 60mm',
      '1f': '44mm x 411mm',
      '1h': '45mm x 296mm',
      '1j': '130mm x 103mm',
      '1jc': '130mm x 103mm',
      '1jb': '130mm x 103mm',
      '1k': '147mm x 123mm',
      '7a': '140mm x 230mm',
      '7k': '46mm x 120mm',
      '7l': '46mm x 137mm',
      '8a': '48mm x 48mm',
      '8b': '34mm x 49mm',
      '8c': '34mm x 49mm',
      '9n': '51mm x 233mm',
      '9p': '48mm x 231mm',
      '9pr': '48mm x 231mm',
      '9q': '136mm x 225mm',
      '9s': '50mm x 39mm',
      '9w': '13mm x 63mm',
      '9y': '60mm x 77mm',
      '9z': '47mm x 228mm',
    };

    // Make a deep copy so changes are only stored on apply
    $ctrl.pricing = angular.copy(componentPricing['door slab glazing piece'] || {});

    function toggleOtherGlazingStyles() {
      $ctrl.otherRanges = [];
      $ctrl.otherRangesToApplyTo = {};
      if ($ctrl.applyToOtherRangesBool) {
        const ranges = {};

        // TODO: disable checkboxes for ranges with no matching pieces

        $ctrl.allGlazing.forEach(function (g) {
          ranges[g.name] = ranges[g.name] || [];
          if (!ranges[g.name].includes(g.partCode)) {
            ranges[g.name].push(g.partCode);
          }
        });

        const piecesForThisRange = $ctrl.glazingPieces.map(function (p) {
          return p.partCode;
        });

        Object.keys(ranges)
          .sort()
          .forEach(function (r) {
            if (r !== $ctrl.rangeName) {
              $ctrl.otherRanges.push({
                name: r,
                pieces: ranges[r].filter(function (p) {
                  return piecesForThisRange.includes(p);
                }),
              });
            }
          });
      }
    }

    function apply() {
      if ($ctrl.applyToOtherRangesBool) {
        applyToOtherRanges();
      }
      close($ctrl.pricing);
    }

    function applyToOtherRanges() {
      const pieceToPriceMap = {};
      $ctrl.glazingPieces.forEach(function (p) {
        const pieceName = p.partCode;
        const pricing = $ctrl.pricing[p.id];
        pieceToPriceMap[pieceName] = pricing;
      });

      const pieceNames = Object.keys(pieceToPriceMap);

      $ctrl.allGlazing.forEach(function (g) {
        if (pieceNames.includes(g.partCode) && $ctrl.otherRangesToApplyTo[g.name]) {
          $ctrl.pricing[g.id] = pieceToPriceMap[g.partCode];
        }
      });
    }
  }
})();
