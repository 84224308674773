(function () {
  'use strict';

  angular
    .module('module.settings.pricingAndQuoting')
    .controller('controller.settings.pricingAndQuoting.pricing', [
      'SettingsService',
      'ProductsService',
      'dataSettings',
      '$filter',
      'Notification',
      'CurrencyService',
      controller,
    ]);

  function controller(
    SettingsService,
    ProductsService,
    dataSettings,
    $filter,
    Notification,
    CurrencyService,
  ) {
    /* jshint validthis: true */
    var vm = this;

    vm.update = update;
    vm.saving = false;
    vm.copyToFixedAmountNotBySize = copyToFixedAmountNotBySize;
    vm.changeFASamePerProduct = changeFASamePerProduct;
    vm.checkIfProductsHaveSameFixedAmountPerSize = checkIfProductsHaveSameFixedAmountPerSize;
    vm.changeFAVaryBySize = changeFAVaryBySize;
    vm.onChangeInput = onChangeInput;
    vm.showConfirmation = showConfirmation;
    vm.onCheckboxChange = onCheckboxChange;
    vm.sameForProducts = true;

    CurrencyService.getSettingsAsync().then((data) => {
      vm.currencySymbol = data.currencySymbol;
    });
    var iniVal = null;
    init();

    /**
     * Init controller. We do not store whether the retailer is varying fixed
     * amounts by size or job type, so inspect the contents of the product
     * settings and check if they are equal to decide whether to start the boxes
     * ticked or not
     */
    function init() {
      vm.settings = dataSettings.settings;
      vm.fixedAmountSettings = {
        use: vm.settings.useFixedAmountPerProduct !== 'no',
        same: vm.settings.useFixedAmountPerProduct === 'yes',
      };

      vm.settings.productSettings = $filter('orderBy')(
        vm.settings.productSettings,
        ['-manufacturer', '-material', '-name'],
        true,
      );

      iniVal = angular.copy(vm.settings);
      vm.iniVal = angular.copy(iniVal);
      var allEqual = checkIfAllEqual(vm.settings.productSettings);

      vm.fixedAmountSettings.varyBySize =
        vm.settings.useFixedAmountPerProductSize && !allEqual.sizes;
      vm.fixedAmountSettings.varyByWorkType =
        vm.settings.useFixedAmountPerProductSize && !allEqual.workTypes;
      if (
        (vm.settings.useFixedAmountPerProduct === 'yes' ||
          vm.settings.useFixedAmountPerProduct === 'products') &&
        checkIfProductsHaveSameFixedAmountPerSize(vm.settings.productSettings) &&
        !allEqual.workTypes &&
        vm.settings.useFixedAmountPerProductSize
      ) {
        vm.fixedAmountSettings.same = true;
      }

      // If useFixedAmountPerProductSize is true that means that one of varyBySize and varyByWorkType
      // must have been checked on save, but if everything is equal we can't tell which one it is. If so,
      // set useFixedAmountPerProductSize to false and copy from the per-size fields to the other field.
      if (vm.settings.useFixedAmountPerProductSize && allEqual.sizes && allEqual.workTypes) {
        vm.settings.useFixedAmountPerProductSize = false;
        copyToFixedAmountNotBySize(vm.settings.productSettings);
      }

      ProductsService.listProducts().then(function (result) {
        vm.settings.productSettings.forEach(function (product) {
          var matchingProduct = result.find(function (p) {
            return p.id === product.id;
          });
          var typeId = matchingProduct.metadata.productType.id;
          product.sizes = getTransitionSizesFromPTID(typeId);
          product.isCoupled = typeId === 11;
        });
      });
    }

    function resetFlags() {
      angular.extend(vm, {
        saving: false,
        hasChangedCheckbox: false,
        inputChange: false,
        updateCheckbox: false,
        updateInput: false,
      });
    }

    /**
     * Save pricing settings to API
     */
    function update() {
      cleanData();
      vm.saving = true;

      // mark that pricing settings have been saved
      vm.settings.pricingSettingsConfirmed = true;

      SettingsService.updateSettings(dataSettings).finally(function () {
        resetFlags();
        init();
      });
    }

    /**
     * Clean settings data
     */
    function cleanData() {
      vm.settings.useFixedAmountPerProduct = getTriStateValue(vm.fixedAmountSettings);

      var byWorkType = vm.fixedAmountSettings.varyByWorkType;
      var bySize = vm.fixedAmountSettings.varyBySize;

      // There's no retailer-wide place to store fixed amount by size
      if ((byWorkType || bySize) && vm.fixedAmountSettings.use) {
        vm.settings.useFixedAmountPerProduct = 'products';
      }

      copyProductSettings(vm.settings.productSettings, bySize, byWorkType);

      vm.settings.useFixedAmountPerProductSize = byWorkType || bySize;

      // clear fixed amount per quote if not set
      if (!vm.settings.useFixedAmountPerQuote) {
        vm.settings.fixedAmountPerQuote = 0;
      }

      // clear fixed amount per facet if not set
      if (!vm.settings.useFixedAmountPerRetailerProductFacet) {
        vm.settings.fixedAmountPerRetailerProductFacet = 0;
      }

      // clear day rate if not set
      if (!vm.settings.useFittingDays) {
        vm.settings.fittingDayRate = null;
      }
    }

    /**
     * Determine value for fixed amount and markup tristates
     *
     * @param {object} triStateSettings
     * @returns {string}
     */
    function getTriStateValue(triStateSettings) {
      if (!triStateSettings.use) {
        return 'no';
      }

      if (!triStateSettings.same) {
        return 'products';
      }

      return 'yes';
    }

    /**
     * Copy form values to the hidden fields, for example from small to medium
     * and large if we're not varying by size, or from the first product to the
     * rest if "Same fixed amount for all products" is ticked. Modifies in-place
     * and also returns the new value.
     *
     * @param {array} productSettings
     * @returns {array} productSettings
     */
    function copyProductSettings(products, bySize, byWorkType) {
      products.forEach(function (product, index) {
        var workTypes = Object.keys(product.fixedAmountPerSize);

        // The API complains is the fixed amount per product fields are null, so
        // set it to zero here and then it will get copied to those fields
        product.fixedAmount = product.fixedAmount || 0;

        // If 'vary by size' and 'vary by work type' are unticked, copy from
        // product.fixedAmount to the whole product.fixedAmountPerSize object
        // If 'vary by size' is unticked, copy the values from .small into
        // .medium and .large
        if (!bySize) {
          workTypes.forEach(function (workType) {
            var sml = product.fixedAmountPerSize[workType];
            sml.medium = sml.small;
            sml.large = sml.small;
          });
        }

        // If 'vary by work type' is unticked, copy the values from the first
        // work type into the others
        if (bySize && !byWorkType) {
          workTypes.forEach(function (workType) {
            var first = product.fixedAmountPerSize[workTypes[0]];
            var current = product.fixedAmountPerSize[workType];
            ['small', 'medium', 'large'].forEach(function (size) {
              current[size] = first[size];
            });
          });
        }

        if (vm.fixedAmountSettings.same) {
          if (byWorkType || bySize) {
            if (index !== 0) {
              var firstObj = vm.settings.productSettings[0].fixedAmountPerSize;
              product.fixedAmountPerSize = JSON.parse(JSON.stringify(firstObj));
            }
          }
        }
        if (bySize || byWorkType) {
          var equal = checkIfAllEqual(vm.settings.productSettings);
          if (equal.sizes && equal.workTypes) {
            product.fixedAmount =
              vm.settings.productSettings[index].fixedAmountPerSize[workTypes[0]].small;
          }
        }
      });
      return products;
    }

    /**
     * Check if the values given vary by size, work type and product to decide
     * whether to tick the checkboxes
     *
     * @param {array} productSettings
     * @returns {object}
     */
    function checkIfAllEqual(products) {
      var allSizesEqual = true;
      var allWorkTypesEqual = true;

      products.forEach(function (product) {
        var workTypes = Object.keys(product.fixedAmountPerSize);

        workTypes.forEach(function (workType) {
          var obj = product.fixedAmountPerSize[workType];
          if (obj.small !== obj.medium || obj.medium !== obj.large) {
            allSizesEqual = false;
          }
          var firstWorkType = product.fixedAmountPerSize[workTypes[0]];
          var currentWorkType = product.fixedAmountPerSize[workType];

          ['small', 'medium', 'large'].forEach(function (size) {
            if (firstWorkType[size] !== currentWorkType[size]) {
              allWorkTypesEqual = false;
            }
          });
        });
      });

      return {
        sizes: allSizesEqual,
        workTypes: allWorkTypesEqual,
      };
    }

    /**
     * Check if all products have the same fixed amount for each work type across all sizes.
     *
     * @param {array} products
     * @returns {boolean}
     */
    function checkIfAllProductsHaveSameFixedAmount(products) {
      if (products.length === 0) {
        return true;
      }
      const baselineFixedAmount = products[0].fixedAmountPerSize;

      return products.every((product) => {
        return Object.keys(baselineFixedAmount).every((workType) => {
          if (!product.fixedAmountPerSize[workType]) {
            return false;
          }

          return ['small', 'medium', 'large'].every((size) => {
            return (
              product.fixedAmountPerSize[workType][size] === baselineFixedAmount[workType][size]
            );
          });
        });
      });
    }

    /**
     * Check if all products have the same fixedAmountPerSize across all products.
     *
     * @param {array} products
     * @returns {boolean}
     */
    function checkIfProductsHaveSameFixedAmountPerSize(products) {
      if (products.length === 0) {
        return true;
      }

      const baselineFixedAmount = JSON.stringify(products[0].fixedAmountPerSize);

      return products.every((product) => {
        return JSON.stringify(product.fixedAmountPerSize) === baselineFixedAmount;
      });
    }

    function onChangeInput() {
      vm.inputChange = true;
    }

    function onCheckboxChange(modalName) {
      vm.hasChangedCheckbox = true;

      if (vm.hasChangedCheckbox && vm.inputChange) {
        showConfirmation(modalName);
      } else {
        if (modalName === 'use') {
          vm.settings.fixedAmountPerProduct = angular.copy(iniVal.fixedAmountPerProduct);
          vm.settings.productSettings.forEach(function (product, index) {
            product.fixedAmount = angular.copy(vm.iniVal.productSettings[index].fixedAmount);
            product.fixedAmountPerSize = angular.copy(
              vm.iniVal.productSettings[index].fixedAmountPerSize,
            );
          });
          var allEqual = checkIfAllEqual(vm.settings.productSettings);
          var checkEqual = checkIfAllProductsHaveSameFixedAmount(vm.settings.productSettings);

          vm.fixedAmountSettings.varyBySize =
            vm.settings.useFixedAmountPerProductSize && !allEqual.sizes;
          vm.fixedAmountSettings.varyByWorkType =
            vm.settings.useFixedAmountPerProductSize && !allEqual.workTypes;
          vm.fixedAmountSettings.same = !vm.fixedAmountSettings.varyBySize && checkEqual;

          // If useFixedAmountPerProductSize is true that means that one of varyBySize and varyByWorkType
          // must have been checked on save, but if everything is equal we can't tell which one it is. If so,
          // set useFixedAmountPerProductSize to false and copy from the per-size fields to the other field.
          if (vm.settings.useFixedAmountPerProductSize && allEqual.sizes && allEqual.workTypes) {
            vm.settings.useFixedAmountPerProductSize = false;
            copyToFixedAmountNotBySize(vm.settings.productSettings);
          }

          ProductsService.listProducts().then(function (result) {
            vm.settings.productSettings.forEach(function (product) {
              var matchingProduct = result.find(function (p) {
                return p.id === product.id;
              });
              var typeId = matchingProduct.metadata.productType.id;
              product.sizes = getTransitionSizesFromPTID(typeId);
              product.isCoupled = typeId === 11;
            });
          });
          return;
        }
        handleCheckboxChange(modalName);
      }
    }

    function handleCheckboxChange(modalName) {
      switch (modalName) {
        case 'same':
          changeFASamePerProduct();
          if (!vm.fixedAmountSettings.same) {
            vm.sameForProducts = false;
          } else {
            vm.sameForProducts = true;
          }
          break;
        case 'varyBySize':
          changeFAVaryBySize();
          if (!vm.fixedAmountSettings.varyBySize) {
            vm.fixedAmountSettings.same =
              vm.iniVal.useFixedAmountPerProduct === 'yes' && vm.sameForProducts;
          }
          break;
        case 'varyByWorkType':
          break;
      }
    }

    function showConfirmation(modalName) {
      const confirmation = window.confirm(
        'You have unsaved changes. Are you sure you want to proceed?',
      );

      if (!confirmation) {
        vm.hasChangedCheckbox = false;
        revertCheckboxChanges(modalName);
      } else {
        restoreOriginalSettings();
        handleCheckboxChange(modalName);
      }
    }

    function revertCheckboxChanges(modalName) {
      if (modalName === 'same') {
        vm.fixedAmountSettings.same = !vm.fixedAmountSettings.same;
        if (vm.fixedAmountSettings.same) {
          vm.fixedAmountSettings.varyBySize = false;
        }
      } else if (modalName === 'varyBySize') {
        vm.fixedAmountSettings.varyBySize = !vm.fixedAmountSettings.varyBySize;
        if (vm.fixedAmountSettings.varyBySize) {
          vm.fixedAmountSettings.same = false;
        }
      } else if (modalName === 'varyByWorkType') {
        vm.fixedAmountSettings.varyByWorkType = !vm.fixedAmountSettings.varyByWorkType;
      } else if (modalName === 'use') {
        if (vm.fixedAmountSettings.use) {
          vm.fixedAmountSettings.use = !vm.fixedAmountSettings.use;
          vm.settings.fixedAmountPerProduct = angular.copy(iniVal.fixedAmountPerProduct);
          vm.settings.productSettings.forEach(function (product, index) {
            product.fixedAmount = angular.copy(vm.iniVal.productSettings[index].fixedAmount);
            product.fixedAmountPerSize = angular.copy(
              vm.iniVal.productSettings[index].fixedAmountPerSize,
            );
          });
          var allEqual = checkIfAllEqual(vm.settings.productSettings);
          var checkEqual = checkIfAllProductsHaveSameFixedAmount(vm.settings.productSettings);

          vm.fixedAmountSettings.varyBySize =
            vm.settings.useFixedAmountPerProductSize && !allEqual.sizes;
          vm.fixedAmountSettings.varyByWorkType =
            vm.settings.useFixedAmountPerProductSize && !allEqual.workTypes;
          vm.fixedAmountSettings.same = !vm.fixedAmountSettings.varyBySize && checkEqual;

          // If useFixedAmountPerProductSize is true that means that one of varyBySize and varyByWorkType
          // must have been checked on save, but if everything is equal we can't tell which one it is. If so,
          // set useFixedAmountPerProductSize to false and copy from the per-size fields to the other field.
          if (vm.settings.useFixedAmountPerProductSize && allEqual.sizes && allEqual.workTypes) {
            vm.settings.useFixedAmountPerProductSize = false;
            copyToFixedAmountNotBySize(vm.settings.productSettings);
          }

          ProductsService.listProducts().then(function (result) {
            vm.settings.productSettings.forEach(function (product) {
              var matchingProduct = result.find(function (p) {
                return p.id === product.id;
              });
              var typeId = matchingProduct.metadata.productType.id;
              product.sizes = getTransitionSizesFromPTID(typeId);
              product.isCoupled = typeId === 11;
            });
          });
        } else {
          vm.fixedAmountSettings.use = !vm.fixedAmountSettings.use;
        }
      }
    }

    function restoreOriginalSettings() {
      vm.settings.productSettings = angular.copy(iniVal.productSettings);
      for (let index = 0; index < vm.settings.productSettings.length; index++) {
        vm.settings.productSettings[index].fixedAmountPerSize = angular.copy(
          iniVal.productSettings[index].fixedAmountPerSize,
        );
        vm.settings.productSettings[index].fixedAmount = angular.copy(
          iniVal.productSettings[index].fixedAmount,
        );
      }
      vm.settings.fixedAmount = angular.copy(iniVal.fixedAmount);
      vm.settings.fixedAmountPerProduct = angular.copy(iniVal.fixedAmountPerProduct);
      vm.hasChangedCheckbox = true;
      vm.inputChange = false;
    }

    /**
     * Copy the value from the first box when varying by size and/or work type
     * into the fixedAmount field
     *
     * @param {array} productSettings
     * @returns {object}
     */
    function copyToFixedAmountNotBySize() {
      vm.hasChangedCheckbox = false;
    }

    function changeFASamePerProduct() {
      vm.hasChangedCheckbox = false;
      if (vm.fixedAmountSettings.same) {
        if (vm.fixedAmountSettings.varyBySize === false) {
          return;
        }
        vm.fixedAmountSettings.varyBySize = false;
        Notification.error(
          'Cannot vary fixed amount by size with the same fixed amount for all products',
        );
      }
    }

    function changeFAVaryBySize() {
      vm.hasChangedCheckbox = false;
      if (vm.fixedAmountSettings.varyBySize) {
        if (vm.fixedAmountSettings.same === false) {
          return;
        }
        vm.fixedAmountSettings.same = false;
        Notification.error(
          'Cannot set the same fixed amount for all products while fixed amount varies by size',
        );
      }
      vm.copyToFixedAmountNotBySize();
    }

    function getTransitionSizesFromPTID(productTypeId) {
      // Note: this is hardcoded and should always match the values in the VS:
      // app/utils/pricing/pricing.js
      var itemSizes = {
        window: {
          m: 1.44,
          l: 2.25,
        },
        entranceDoor: {
          m: 2.5,
          l: 4.25,
        },
        doubleDoor: {
          m: 5,
          l: 6.5,
        },
        bifold: {
          m: 6.6,
          l: 10.5,
        },
        sash: {
          m: 1.5,
          l: 3,
        },
      };

      if (productTypeId === 3) {
        return itemSizes.sash;
      } else if (productTypeId === 5 || productTypeId === 6) {
        return itemSizes.entranceDoor; // entrance or composite
      } else if (productTypeId === 7) {
        return itemSizes.doubleDoor;
      } else if (productTypeId === 9 || productTypeId === 10) {
        return itemSizes.bifold; // patio or bifold
      }
      return itemSizes.window;
    }
  }
})();
