(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuotePricingTaxRow', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.quotes.pricingTaxRow.directive.template.html',
      controllerAs: '$ctrl',
      scope: {
        salesTaxRate: '<',
        costPrice: '=?',
        sellPrice: '=?',
        readonly: '<',
        onUpdate: '<',
      },
      controller: ['AuthenticationService', 'CurrencyService', controller],
      bindToController: true,
    };
  }

  function controller(AuthenticationService, CurrencyService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.editing = false;
    $ctrl.updating = false;

    $ctrl.showCostPrice = false;
    $ctrl.showSellPrice = typeof $ctrl.sellPrice !== 'undefined';

    $ctrl.costPricePresent = typeof $ctrl.costPrice === 'number';
    $ctrl.sellPricePresent = typeof $ctrl.sellPrice === 'number';

    AuthenticationService.isManagerUser().then(function (isManager) {
      $ctrl.showCostPrice = isManager;
    });

    CurrencyService.getSettingsAsync().then((data) => {
      $ctrl.currencySettings = data;
    });

    $ctrl.update = function (valid) {
      var quote__taxpercentage = document.getElementById('quote__tax-percentage');
      setTimeout(function () {
        quote__taxpercentage.focus();
        quote__taxpercentage.style.border = '1px solid #0067b5';
      }, 10);
      if (!valid) {
        return;
      }

      if ($ctrl.editing) {
        $ctrl.updating = true;

        $ctrl
          .onUpdate($ctrl.salesTaxRate)
          .then(function () {
            $ctrl.editing = false;
          })
          .finally(function () {
            $ctrl.updating = false;
          });
      } else {
        $ctrl.editing = true;
      }
    };
  }
})();
