(function () {
  'use strict';

  angular
    .module('module.settings.partsShop')
    .factory('PartsShopApiService', ['$q', 'Notification', 'ApiService', 'UploadService', service]);

  function service($q, Notification, ApiService, UploadService) {
    return {
      list,
      pollList,
      create,
      retrieve,
      update,
      copy,
      remove,
      listProductLibrary,
      addLibraryProducts,
    };

    /**
     * @returns {Promise}
     */
    function list() {
      return ApiService.get('/custom-item-iemplates').then((response) => response.data);
    }

    /**
     * @returns {{cancel: Function, promise: Promise}}
     */
    function pollList() {
      return ApiService.poll(
        () => ApiService.get('/custom-item-templates'),
        (items) => !listShouldBePolled(items),
      );
    }

    function listProductLibrary(retailer) {
      return ApiService.get(
        `/companies/retailers/${retailer.id}/custom-item-templates/library`,
      ).then(function (response) {
        return response.data;
      });
    }
    function addLibraryProducts(retailer, templates) {
      var data = {
        templates: templates,
      };

      return ApiService.post(
        '/companies/retailers/' + retailer.id + '/custom-item-templates',
        data,
      ).then(
        function (response) {
          Notification.success('Added Parts Shop library products');

          return null;
        },
        function () {
          Notification.error('Failed to add Parts Shop library products');

          return $q.reject();
        },
      );
    }
    /**
     * @param {Array} items
     * @returns {boolean}
     */
    function listShouldBePolled(items) {
      // check if there are any items that have an image but no thumbnail (processing not complete)
      return items.some((item) => item.imageUrl && !item.thumbnailUrl);
    }

    /**
     * @param {Object} item
     * @param {File|null} imageFile
     * @returns {Promise}
     */
    function create(item, imageFile) {
      const url = `/custom-item-templates`;

      return uploadImage(imageFile).then((imageId) => {
        item.image = imageId;

        return ApiService.post(url, item).then(
          (response) => {
            Notification.success('Added to Parts Shop');

            return response.data;
          },
          () => {
            Notification.error('Failed to add to Parts Shop');

            return $q.reject();
          },
        );
      });
    }

    /**
     * @param {File|null} imageFile
     * @returns {Promise}
     */
    function uploadImage(imageFile) {
      if (!imageFile) {
        return $q.when(null);
      }

      return UploadService.uploadImage(imageFile).then((file) => file.resourceId);
    }

    /**
     * @param {string} itemId
     * @returns {Promise}
     */
    function retrieve(itemId) {
      const url = `/custom-item-templates/${itemId}`;

      return ApiService.get(url).then((response) => response.data);
    }

    /**
     * @param {string} itemId
     * @param {Object} item
     * @param {File|null} imageFile
     * @returns {Promise}
     */
    function update(itemId, item, imageFile) {
      const url = `/custom-item-templates/${itemId}`;

      const payload = {
        name: item.name,
        category: item.category,
        costPrice: item.costPrice,
        sellPrice: item.sellPrice,
        supplier: item.supplier,
        supplierQuoteReference: item.supplierQuoteReference,
        pricingUnit: item.pricingUnit,
        discountable: item.discountable,
        showAsDedicatedPage: false,
        notes: item.notes,
      };

      return uploadImage(imageFile).then((imageId) => {
        payload.image = imageId ? imageId : item.imageId;

        return ApiService.put(url, payload).then(
          (response) => {
            Notification.success('Updated in Parts Shop');

            return response.data;
          },
          () => {
            Notification.error('Failed to update');

            return $q.reject();
          },
        );
      });
    }

    /**
     * @param {string} itemId
     * @returns {Promise}
     */
    function copy(itemId) {
      return ApiService.post(`/custom-item-templates/${itemId}/copy`).then(
        (response) => {
          Notification.success('Copied to Parts Shop');

          return response.data;
        },
        () => {
          Notification.error('Failed to copy');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} itemId
     * @returns {Promise}
     */
    function remove(itemId) {
      const url = `/custom-item-templates/${itemId}`;

      return ApiService.delete(url).then(
        (response) => {
          Notification.success('Deleted from Parts Shop');

          return response.data;
        },
        () => {
          Notification.error('Failed to delete');

          return $q.reject();
        },
      );
    }
  }
})();
