(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteItemSlice', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.quotes.itemSlice.directive.template.html',
        controllerAs: 'sliceVm',
        scope: {
          quote: '=',
          item: '=',
          showFinancials: '=',
          showCostPrice: '=',
          allowSellingPriceEdit: '=',
          type: '<',
        },
        controller: [
          '$scope',
          '$state',
          'QuoteItemsService',
          'CustomItemService',
          'QuotePricingService',
          'CurrencyService',
          'Notification',
          controller,
        ],
        bindToController: true,
      };
    },
  ]);

  function controller(
    $scope,
    $state,
    QuoteItemsService,
    CustomItemService,
    QuotePricingService,
    CurrencyService,
    Notification,
  ) {
    /* jshint validthis: true */
    var vm = this;
    vm.location = vm.item.metadata.location;
    CurrencyService.getSettingsAsync().then((data) => {
      vm.currencySymbol = data.currencySymbol;
    });

    var valid = vm.item.metadata.itemValid;
    vm.generic = vm.item.genman && vm.item.genman.isGenericProduct;
    vm.retailerPricingActive =
      vm.item.genman && vm.item.genman.pricing && vm.item.genman.pricing.itemPricing === 'active';
    vm.showCostInput = vm.generic && valid && !vm.retailerPricingActive;
    vm.focusLocation = focusLocation;

    vm.updatePricing = function (glassCost) {
      function thenUpdate() {
        // Coupled items are _weird_ as you have to save the parent-only price to the API but the API
        // returns you the price including the children in the pricing object. So when saving the cost
        // and sell price of a coupled item, subtract the children's cost and selling prices.
        var childrenCostPrice = 0;
        var childrenSellPrice = 0;

        if (vm.item.metadata.children && vm.item.metadata.children.length) {
          vm.item.metadata.children.forEach(function (child) {
            childrenCostPrice += child.costPrice;
            childrenSellPrice += child.sellPrice;
          });
        }

        QuoteItemsService.updateItemPricing(vm.quote, vm.item, {
          costPrice: vm.item.pricing.costPrice - childrenCostPrice,
          sellPrice: vm.item.pricing.sellPrice - childrenSellPrice,
          glassCostPrice: vm.item.pricing.glassCostPrice,
          customGlassCost: glassCost,
        });
      }

      if (glassCost) {
        vm.item.metadata.customGlassCost = true;
      }
      if (vm.item.metadata.customSellPrice) {
        thenUpdate();
      } else {
        QuotePricingService.recalculateItemSellingPrice(vm.item).then(thenUpdate);
      }
    };

    vm.updateCustomItemPricing = function () {
      var objectForSave = angular.copy(vm.item.metadata);
      delete objectForSave.quote;
      CustomItemService.updateCustomItem(vm.quote.id, vm.item.id, objectForSave).then(function () {
        QuotePricingService.updateQuotePricing(vm.quote);
      });
    };

    vm.updateSellingPriceOnly = function () {
      vm.item.metadata.customSellPrice = true;
      var costPriceToSave = vm.item.pricing.costPrice;
      var sellPriceToSave = vm.item.pricing.sellPrice;
      if (vm.item.metadata.isParent) {
        for (var index = 0; index < vm.item.metadata.children.length; index++) {
          var child = vm.item.metadata.children[index];
          sellPriceToSave -= child.sellPrice;
          costPriceToSave -= child.costPrice;
        }
      }
      QuoteItemsService.updateItemPricing(vm.quote, vm.item, {
        costPrice: costPriceToSave,
        sellPrice: sellPriceToSave,
        customSellPrice: true,
      });
    };

    vm.resetCustomSellPrice = function () {
      vm.item.metadata.customSellPrice = false;
      QuotePricingService.recalculateItemSellingPrice(vm.item).then(function () {
        QuoteItemsService.updateItemPricing(vm.quote, vm.item, {
          costPrice: vm.item.pricing.costPrice,
          sellPrice: vm.item.pricing.sellPrice,
          customSellPrice: false,
        });
      });
    };

    vm.editCustomItem = () => {
      const state = vm.item.metadata.fromTemplate
        ? 'sales.quotes.editItemFromPartsShop'
        : 'sales.quotes.editCustomItem';

      $state.go(state, {quoteId: vm.quote.id, customItemId: vm.item.id});
    };

    $scope.$watch('sliceVm.allowSellingPriceEdit', function (isTrue, wasTrue) {
      if (!isTrue && wasTrue && vm.item.metadata.customSellPrice) {
        vm.resetCustomSellPrice();
      }
    });

    function focusLocation() {
      var editLocations = document.getElementsByClassName('location');
      setTimeout(function () {
        for (var i = 0; i < editLocations.length; i++) {
          editLocations[i].focus();
          editLocations[i].style.border = '1px solid #0067b5';
        }
      }, 10);
    }

    /**
     * Update quote on location updating
     */
    vm.toggleEditLocation = function () {
      if (
        (vm.location && vm.type === 'normal') ||
        ((vm.location || !vm.location) && vm.type !== 'normal')
      ) {
        vm.item.metadata.location = vm.location;
        vm.item.metadata.type = vm.type;
        QuoteItemsService.updateLocation(vm.quote, vm.item, vm.location, vm.type);
        vm.editingLocation = !vm.editingLocation;
      }
    };
  }
})();
