(function () {
  'use strict';

  angular.module('module.termsAndConditions').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('termsAndConditions', {
      abstract: true,
      url: '/terms-and-conditions',
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
      },
      ncyBreadcrumb: {
        skip: true,
      },
    });

    $stateProvider.state('termsAndConditions.list', {
      url: '',
      views: {
        'content@': {
          controller: 'controller.termsAndConditions.list',
          controllerAs: 'termsVm',
          templateUrl: 'templates/termsAndConditions.list.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Terms & Conditions',
      },
      data: {
        meta: {
          titleSuffix: '|  Terms And Conditions',
        },
      },
    });

    $stateProvider.state('termsAndConditions.create', {
      url: '/create',
      views: {
        'content@': {
          controller: 'controller.termsAndConditions.create',
          controllerAs: 'termsVm',
          templateUrl: 'templates/termsAndConditions.create.template.html',
          resolve: {
            dataItem: () => null,
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Create',
      },
      data: {
        meta: {
          titleSuffix: '| New',
        },
      },
    });
  }
})();
