(function () {
  'use strict';

  angular
    .module('module.customItem')
    .factory('PricingUnitsService', ['CurrencyService', 'PRICING_UNITS', service]);

  function service(CurrencyService, PRICING_UNITS) {
    return {
      getFilteredUnits,
    };

    function getFilteredUnits(keepUnit) {
      keepUnit = keepUnit || null;

      return CurrencyService.getSettingsAsync().then((settings) => {
        return PRICING_UNITS.filter((unit) => {
          return (
            unit.type === settings.measurementType || unit.type == null || unit.key === keepUnit
          );
        });
      });
    }
  }
})();
