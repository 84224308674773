(function () {
  'use strict';

  angular
    .module('module.translations')
    .factory('TranslationService', [
      '$rootScope',
      '$q',
      '$log',
      'ApiService',
      'SettingsService',
      'bugsnagClient',
      service,
    ]);

  function service($rootScope, $q, $log, ApiService, SettingsService, bugsnagClient) {
    let translations = null,
      locale = null,
      translationsPromise = null;

    $rootScope.$on('settings.update', (event, settings) => {
      if (!settings) {
        return;
      }

      setLocale(settings.metadata.locale);
    });

    loadTranslations();
    function loadTranslations() {
      if (translationsPromise !== null) {
        return translationsPromise;
      }

      translationsPromise = ApiService.get('/translations')
        .then((response) => {
          translations = response.data;
        })
        .catch((error) => {
          bugsnagClient.notify('Error loading translations: ' + (error.data || error.statusText));
          return $q.reject();
        });

      // If locale missing, force settings fetch, ignoring failure
      if (locale === null) {
        translationsPromise = translationsPromise.then(() => {
          return SettingsService.getSettings().then(
            (settings) => setLocale(settings.metadata.locale),
            (error) => setLocale(null),
          );
        });
      }

      return translationsPromise;
    }

    function translate(key) {
      if (translations === null) {
        const error = 'Call to translate before translations are loaded';
        $log.error(error);
        bugsnagClient.notify(error);

        return key;
      }

      if (locale === null) {
        const error = 'Call to translate before locale is set';
        $log.error(error);
        bugsnagClient.notify(error);

        return key;
      }

      if (typeof translations[key.toLowerCase()] === 'undefined') {
        const error = `Missing translation for key: "${key}" in locale: "${locale}"`;
        $log.error(error);
        bugsnagClient.notify(error);

        return key;
      }

      return translations[key.toLowerCase()][locale];
    }

    function setLocale(newLocale) {
      locale = newLocale;
    }

    return {
      loadTranslations,
      setLocale,
      translate,
    };
  }
})();
