(function () {
  'use strict';

  angular
    .module('module.retailers')
    .controller('controller.retailers.ownerView.termsConditions', [
      '$window',
      'RetailersService',
      'dataRetailer',
      controller,
    ]);

  function controller($window, RetailersService, dataRetailer) {
    /* jshint validthis: true */
    var $ctrl = this;
    $ctrl.resetTermsCondition = resetTermsCondition;

    $ctrl.retailer = dataRetailer;

    function resetTermsCondition(retailerId) {
      var accept = $window.confirm('Terms and Conditions will get reset');
      if (accept) {
        RetailersService.resetTermsAndConditions(retailerId);
      }
    }
  }
})();
