(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuotePricingAdjustmentRow', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.quotes.pricingAdjustmentRow.directive.template.html',
      controllerAs: '$ctrl',
      scope: {
        readonly: '<',
        showOnQuote: '=',
        label: '=',
        value: '=',
        onUpdate: '<',
        hasNonDiscountableItems: '<',
      },
      bindToController: true,
      controller: [
        '$scope',
        'AuthenticationService',
        'SettingsService',
        'CurrencyService',
        controller,
      ],
    };
  }

  function controller($scope, AuthenticationService, SettingsService, CurrencyService) {
    /* jshint validthis: true */
    var vm = this;

    vm.update = update;
    vm.editAdjustment = editAdjustment;

    CurrencyService.getSettingsAsync().then((data) => {
      vm.currencySymbol = data.currencySymbol;
    });

    vm.showOnQuoteReadOnly = true;
    vm.showOnQuoteReadOnlyValue = false;
    SettingsService.getSettings().then(function (data) {
      vm.settings = data.settings;
      updateShowOnQuote();
    });

    vm.showCostPrice = false;
    AuthenticationService.isManagerUser().then(function (isManager) {
      vm.showCostPrice = isManager;
    });

    $scope.$watchGroup(['$ctrl.showOnQuote', '$ctrl.value'], function (newValues, oldValues) {
      if (!angular.equals(newValues, oldValues)) {
        update();
      }
    });

    function update() {
      updateShowOnQuote();
      vm.onUpdate();
    }

    function updateShowOnQuote() {
      vm.showOnQuoteReadOnly = vm.settings.showItemPricing;
      vm.showOnQuoteReadOnlyValue = vm.value > 0 || vm.label !== '';
    }

    function editAdjustment() {
      var Adjustment = document.getElementById('Adjustment');
      setTimeout(function () {
        Adjustment.focus();
        Adjustment.style.border = '1px solid #0067b5';
      }, 10);
    }
  }
})();
