(function () {
  'use strict';

  angular
    .module('module.termsAndConditions')
    .controller('controller.termsAndConditions.create', [
      'UploadService',
      'Notification',
      'TermsAndConditionsService',
      '$state',
      controller,
    ]);

  function controller(UploadService, Notification, TermsAndConditionsService, $state) {
    /* jshint validthis: true */
    var vm = this;
    vm.upload = upload;
    vm.create = create;
    vm.addSummaryPoint = addSummaryPoint;
    vm.deleteSummaryPoint = deleteSummaryPoint;

    vm.terms = {};
    vm.stages = []; // Initialize stages array to store summary points

    /**
     * Upload a document to the document store
     *
     * @param {object} file
     * @returns {Promise}
     */
    function upload(file) {
      return UploadService.uploadDocument(file)
        .then(function (uploadedDocument) {
          return uploadedDocument.resourceId;
        })
        .catch(function () {
          handleFileError('Failed to upload file for Terms and Conditions');
        });
    }

    function handleFileError(message) {
      var uploadFile = document.getElementById('file');
      if (uploadFile) {
        setTimeout(function () {
          uploadFile.focus();
          uploadFile.style.color = 'red';
        }, 10);
      }
      Notification.error(message);
    }

    function create() {
      var fileInput = document.getElementById('file');

      if (!fileInput.files.length) {
        return Notification.error('Please select a file to upload');
      }

      vm.terms.file = fileInput.files[0];

      if (!isValidFile(vm.terms.file)) {
        return;
      }

      upload(vm.terms.file).then(function (uploadedResourceId) {
        if (uploadedResourceId) {
          createTermsAndConditions(uploadedResourceId);
        }
      });
    }

    function isValidFile(file) {
      if (!file) {
        return false;
      }

      if (file.type !== 'application/pdf') {
        handleFileError('Please select a PDF file');
        return false;
      }

      return true;
    }

    function deleteSummaryPoint(index) {
      vm.stages.splice(index, 1);
    }

    function addSummaryPoint() {
      if (!vm.stages) {
        vm.stages = [];
      }
      vm.stages.push({summary: ''}); // Add an empty object with a summary property
    }

    // Call addSummaryPoint when the page loads to ensure at least one summary point
    if (!vm.stages || vm.stages.length === 0) {
      addSummaryPoint();
    }

    function createTermsAndConditions(resourceId) {
      var summaryPoints = vm.stages.map(function (stage) {
        return stage.summary;
      });

      vm.terms = {
        name: vm.terms.name,
        summary: summaryPoints,
        upload: resourceId,
      };

      TermsAndConditionsService.createTermsConditions(vm.terms)
        .then(function () {
          $state.go('termsAndConditions.list');
        })
        .finally(function () {
          vm.isSubmitting = false;
        });
    }
  }
})();
