(function () {
  'use strict';

  angular
    .module('module.retailers')
    .controller('controller.retailers.ownerView.details', [
      '$window',
      'RetailersService',
      'dataRetailer',
      controller,
    ]);

  function controller($window, RetailersService, dataRetailer) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.updatePricingPlan = updatePricingPlan;
    $ctrl.onSuspend = onSuspend;
    $ctrl.onRetailerPricingChange = onRetailerPricingChange;
    $ctrl.updateApiAccess = updateApiAccess;

    $ctrl.isEditing = false;
    $ctrl.isSelected = false;
    $ctrl.saveMaxUsers = saveMaxUsers;

    $ctrl.retailer = dataRetailer.retailer;

    $ctrl.plans = dataRetailer.pricingPlans;
    var planCode = $ctrl.retailer.pricingPlan ? $ctrl.retailer.pricingPlan.code : null;
    $ctrl.selectedPlan = $ctrl.plans.find(function (plan) {
      return plan.code === planCode;
    });

    if ($ctrl.selectedPlan) {
      $ctrl.selectedPlan.maxUsers = dataRetailer.retailer.maxActiveUsers;
    }

    function updatePricingPlan() {
      var plan = $ctrl.selectedPlan || {code: null};

      var planToUpdate = {
        code: plan.code,
        maxUsers: parseInt(plan.maxUsers) || null,
      };

      RetailersService.updatePricingPlan($ctrl.retailer, planToUpdate).then(function () {
        $ctrl.retailer.pricingPlan = {
          code: planToUpdate.code,
          maxActiveUsers: planToUpdate.maxUsers,
        };
        $ctrl.isEditing = false;
        $ctrl.isSelected = true;
      });
    }

    function updateApiAccess() {
      var apiAccess = $ctrl.retailer.apiAccess;
      RetailersService.updateApiAccess($ctrl.retailer, apiAccess);
    }

    function onSuspend(suspended) {
      $ctrl.retailer.metadata.suspended = suspended;
    }

    function onRetailerPricingChange(enabled) {
      $ctrl.retailer.retailerPricingAvailable = enabled;
    }

    function saveMaxUsers() {
      if (!$ctrl.selectedPlan.maxUsers && $ctrl.selectedPlan.maxUsers !== 0) {
        return;
      }
      $ctrl.updatePricingPlan();
    }
  }
})();
