(function () {
  'use strict';

  angular.module('module.dashboard').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('dashboard', {
          url: '/',
          views: {
            'navigation@': {
              controller: 'controller.navigation',
              templateUrl: 'templates/navigation.template.html',
              resolve: {
                dataUser: [
                  'AuthenticationService',
                  function (AuthenticationService) {
                    return AuthenticationService.getUser();
                  },
                ],
              },
            },
          },
          onEnter: companyTypeRedirect,
          ncyBreadcrumb: {
            skip: true,
          },
        })

        .state('dashboard.manufacturer', {
          views: {
            'content@': {
              controller: 'controller.dashboard.manufacturer',
              controllerAs: '$ctrl',
              templateUrl: 'templates/dashboard.manufacturer.template.html',
              resolve: {
                currencySettings: [
                  'CurrencyService',
                  function (CurrencyService) {
                    return CurrencyService.getSettingsAsync();
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            label: 'Dashboard',
          },
          data: {
            meta: {
              titleSuffix: '| Dashboard',
            },
          },
        })

        .state('dashboard.owner', {
          views: {
            'content@': {
              controller: 'controller.dashboard.owner',
              controllerAs: 'dashboardVm',
              templateUrl: 'templates/dashboard.owner.template.html',
            },
          },
          data: {
            meta: {
              titleSuffix: '| Dashboard',
            },
          },
        });
    },
  ]);

  companyTypeRedirect.$inject = ['$state', '$rootScope', 'AuthenticationService'];

  function companyTypeRedirect($state, $rootScope, AuthenticationService) {
    AuthenticationService.getUser().then(function (user) {
      if (user.permissions.companyType === 'retailer') {
        if ($rootScope.showNav) {
          return $state.go('sales.customers.list');
        }
        if (
          !user.metadata.mustAcceptTermsAndConditions &&
          !user.metadata.mustAcceptUpdatedTermsAndConditions
        ) {
          $rootScope.showNav = true;
          return $state.go('sales.customers.list');
        }
        if (user.metadata.mustAcceptTermsAndConditions === true) {
          return $state.go('dashboard.terms');
        }
        if (user.metadata.mustAcceptUpdatedTermsAndConditions === true) {
          return $state.go('dashboard.termsUpdated');
        }
      }

      $rootScope.showNav = true;
      $state.go('dashboard.' + user.permissions.companyType);
    });
  }
})();
