(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteItems', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.quotes.quoteItems.directive.template.html',
      controllerAs: 'quoteItemsVm',
      scope: {
        quote: '=',
        showFinancials: '=',
      },
      controller: [
        '$scope',
        'SettingsService',
        'QuotesService',
        'QuoteItemsService',
        'AuthenticationService',
        'CurrencyService',
        'TranslationService',
        controller,
      ],
      bindToController: true,
    };
  }

  function controller(
    $scope,
    SettingsService,
    QuotesService,
    QuoteItemsService,
    AuthenticationService,
    CurrencyService,
    TranslationService,
  ) {
    /* jshint validthis: true */
    var vm = this;
    var pollRunning = false;

    vm.useFittingDays = false;
    vm.useFixedAmountPerQuote = false;
    vm.showCostPrice = false;
    vm.onAdjustmentUpdate = updatePricingIfDraft;
    vm.onTaxRateUpdate = updateTaxRate;
    vm.sellPriceDetailsPopup = sellPriceDetailsPopup;

    vm.salesTaxChanged = false;
    salesTaxChanged();

    vm.salesTaxLabel = function () {
      const taxName = CurrencyService.getSettings().salesTaxType;
      const taxPercent = vm.quote.pricing.vatRate * 100;
      const taxNote = vm.salesTaxChanged ? ', see note' : '';
      return taxName + ' (' + taxPercent + '%' + taxNote + '):';
    };

    SettingsService.getSettings().then(function (settings) {
      vm.useFittingDays = settings.settings.useFittingDays;
      vm.useFixedAmountPerQuote = settings.settings.useFixedAmountPerQuote;
    });

    vm.allowSellingPriceEdit = false;
    vm.showSellingPriceCheckbox = false;

    AuthenticationService.isManagerUser().then(function (isManager) {
      vm.showCostPrice = isManager;

      if (isManager) {
        vm.quote.items.forEach(function (item) {
          if (item.genman && item.genman.isGenericProduct) {
            vm.showSellingPriceCheckbox = true;
          }
        });

        if (vm.showSellingPriceCheckbox) {
          vm.quote.items.forEach(function (item) {
            if (item.metadata.customSellPrice) {
              vm.allowSellingPriceEdit = true;
            }
          });
        }
      }
    });

    $scope.$watch('quoteItemsVm.quote.customItems', updateNonDiscountable, true);
    vm.hasNonDiscountableItems = false;
    function updateNonDiscountable() {
      vm.hasNonDiscountableItems = false;
      vm.quote.customItems.forEach(function (item) {
        if (item.metadata.discountable === false) {
          vm.hasNonDiscountableItems = true;
        }
      });
    }

    // Merge items and custom items into one list, needed when we add sorting
    // to custom items as well as normal items
    // $scope.$watchGroup(['quoteItemsVm.quote.items', 'quoteItemsVm.quote.customItems'], updateSortableList, true);
    // function updateSortableList(oldValues, newValues) {
    //   var normalItems = newValues[0].map(function(item) {
    //     return {
    //       type: 'normal',
    //       item: item
    //     }
    //   });
    //   var customItems = newValues[1].map(function(item) {
    //     return {
    //       type: 'custom',
    //       item: item
    //     }
    //   });
    //   var items = normalItems.concat(customItems);
    //   vm.sortedItems = items;
    // }

    vm.sortableOptions = {
      delay: 200,
      delayOnTouchOnly: true,
      disabled: !vm.quote.metadata.allowItemUpdates,
      filter: '.do-not-drag',
      preventOnFilter: false,
      onEnd: function (event) {
        if (event.newIndex === event.oldIndex) {
          return;
        }
        var position = event.newIndex + 1;
        var item = event.model;
        QuoteItemsService.updateItemPosition(vm.quote, item, position);
      },
    };

    $scope.$watch('quoteItemsVm.quote.metadata.allowItemUpdates', updateSortable, true);

    function updateSortable() {
      vm.sortableOptions.disabled = !vm.quote.metadata.allowItemUpdates;
    }

    /**
     * Checks for missing thumb item images and starts poll if so
     */
    $scope.$watch('quoteItemsVm.quote.items', checkForMissingThumbImages, true);

    function checkForMissingThumbImages() {
      if (!pollRunning && QuoteItemsService.isMissingThumbImages(vm.quote.items)) {
        pollRunning = true;

        $scope.$on('$destroy', function () {
          QuoteItemsService.cancelPollForItemImages();
        });

        QuoteItemsService.pollForItemImages(vm.quote.id).then(
          function (quoteItems) {
            vm.quote.items = quoteItems;
            pollRunning = false;
          },
          function () {},
          function (quoteItems) {
            vm.quote.items = quoteItems;
          },
        );
      }
    }

    function updatePricingIfDraft() {
      if (vm.quote.metadata.status === 'draft') {
        QuotesService.updateQuotePricing(vm.quote).then(QuotesService.updateQuote);
      }
    }

    function updateTaxRate(newTaxRate) {
      return QuotesService.updateQuoteTaxRate(vm.quote, newTaxRate).then(function () {
        vm.quote.pricing.vatRate = newTaxRate;
        salesTaxChanged();

        return QuotesService.updateQuotePricing(vm.quote);
      });
    }

    function sellPriceDetailsPopup() {
      window.alert(TranslationService.translate('Cost price plus fitting, markups, extras, etc.'));
    }
    function salesTaxChanged() {
      vm.salesTaxChanged =
        parseFloat(CurrencyService.getSettings().salesTaxRate) !==
        parseFloat(vm.quote.pricing.vatRate);
    }
  }
})();
