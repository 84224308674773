(function () {
  'use strict';

  const pricingFields = {
    'door handle': ['each'],
    'master handle': ['each'],
    'slave handle': ['each'],
    'folding handle': ['each'],
    'window handle': ['each'],
    'door slab': ['each'],
    'side screen slab': ['each'],
    'finish pairs': ['uplift'],
    'astragal bar': ['area', 'length', 'each'],
    'door hinge': ['each'],
    'door knocker': ['each'],
    letterplate: ['each'],
    paint: ['uplift'],
    'profile trickle vent': ['each'],
    'low threshold': ['each', 'length'],
    'flat panel': ['each', 'area'],
    'feature panel': ['each', 'area'],
    cill: ['each', 'length'],
    addon: ['each', 'length'],
    'friction hinge': ['each'],
    'peg stay': ['each'],
    'tilt turn handle': ['each'],
    'sash lock': ['each'],
    operator: ['each'],
  };

  const noDefaults = [
    'finish pairs',
    'door slab glazing',
    'low threshold',
    'addon',
    'friction hinge',
    'slave handle',
    'folding handle',
  ];

  const coastalSponsoredRanges = ['Coastal'];

  angular
    .module('module.settings.retailerProducts')
    .component('ttRetailerProductComponentOptions', {
      templateUrl: 'templates/settings.retailerProducts.componentOptions.component.template.html',
      bindings: {
        defaultOption: '=?',
        availableOptions: '=',
        optionsList: '=',
        newProduct: '=',
        renameObject: '=',
        name: '@',
        title: '@',
        paintFinishBool: '=',
        allowTogglingPaintFinishBool: '=',
        showPaintPresets: '=',
        range: '=',
        componentPricing: '=',
        showComponentPricing: '=',
        getComponentOptions: '<',
        componentType: '<',
        showCollapseButton: '<',
        fitCillByDefault: '=',
      },
      controller: ['$scope', '$timeout', 'CurrencyService', 'Notification', controller],
    });

  function controller($scope, $timeout, CurrencyService, Notification) {
    /* jshint validthis: true */
    const vm = this;

    vm.checkboxChange = checkboxChange;
    vm.radioChange = radioChange;
    vm.fitCill = fitCill;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.sortComponentOptions = sortComponentOptions;
    vm.applyPreset = applyPreset;
    let defaultCillValue, oldDefaultCillValue;

    CurrencyService.getSettingsAsync().then((data) => {
      vm.currencySymbol = data.currencySymbol;
    });

    $scope.$watch('$ctrl.optionsList', changeOptions);
    $scope.$watch('$ctrl.renameObject', changeRenames, true);
    $scope.$watch('[$ctrl.name, $ctrl.showPaintPresets]', setLayout);

    function checkboxChange(option) {
      if (option && vm.defaultOption === option && !vm.optionBooleans[option]) {
        let done = false;
        angular.forEach(vm.optionBooleans, function (selected, op) {
          if (selected && !done && !op.cannotBeDefault) {
            vm.defaultOption = op;
            done = true;
          }
        });
        if (!done && !noDefault(vm.name)) {
          Notification.error('At least one option must be available');
          vm.optionBooleans[option] = true;
        }
      }

      vm.availableOptions = [];
      angular.forEach(vm.optionBooleans, function (selected, option) {
        if (selected) {
          vm.availableOptions.push(option);
        }
      });
    }

    function radioChange(option) {
      if (!vm.optionBooleans[option]) {
        vm.optionBooleans[option] = true;
        vm.availableOptions.push(option);
      }
    }

    function fitCill() {
      if (vm.name === 'cill') {
        vm.showDefault = vm.fitCillByDefault;
        oldDefaultCillValue = defaultCillValue ? defaultCillValue : vm.defaultOption;
        vm.defaultOption = oldDefaultCillValue;
        angular.forEach(vm.optionsList, function (opt) {
          if (vm.defaultOption === opt.id && vm.showDefault) {
            vm.optionBooleans[opt.id] = true;
            vm.availableOptions.push(opt.id);
          }
        });
        if (vm.fitCillByDefault && (vm.defaultOption === false || vm.defaultOption === null)) {
          setFirstOptionAsDefault();
        }
      }
    }

    function changeOptions() {
      vm.optionBooleans = {};

      angular.forEach(vm.optionsList, function (opt) {
        // Decide whether each option can be renamed. For example, you can
        // rename 'Rosewood' but not 'Rosewood & White Grain' because that name
        // is determined by whether you've renamed 'Rosewood' and 'White Grain'
        // on their own. All other component types can be renamed
        if (vm.name === 'finish pairs') {
          opt.canBeRenamed = !opt.retailerName.includes(' & ');
        } else {
          opt.canBeRenamed = true;
        }

        if (vm.name === 'astragal bar') {
          opt.cannotBeDefault = opt.retailerName.includes('Dummy Transom');
        }

        if (['door slab', 'side screen slab'].includes(vm.name)) {
          opt.imagesToShow = {in: false, out: false, thumb: true};
          opt.tallImage = true;
        } else if (opt.imageInside) {
          if (opt.imageOutside) {
            if (opt.imageInside !== opt.imageOutside || vm.name === 'finish pairs') {
              opt.imagesToShow = {in: true, out: true, thumb: false};
            } else {
              opt.imagesToShow = {in: true, out: false, thumb: false};
            }
          } else {
            opt.imagesToShow = {in: true, out: false, thumb: false};
          }
        } else if (opt.imageOutside) {
          opt.imagesToShow = {in: false, out: true, thumb: false};
        } else if (opt.thumb) {
          opt.imagesToShow = {in: false, out: false, thumb: true};
        } else if (opt.hex) {
          opt.imagesToShow = {in: false, out: false, thumb: false, hex: true};
        }
      });

      // Note: If the saved retailer product doesn't have availableOptions set
      // at all, all options are available

      if (vm.newProduct || !vm.availableOptions) {
        if (!vm.optionsList || !vm.optionsList.length) {
          vm.availableOptions = null;
        } else {
          vm.availableOptions = [];
          angular.forEach(vm.optionsList, function (opt, index) {
            vm.optionBooleans[opt.id] = true;
            vm.availableOptions.push(opt.id);
          });
        }
      } else {
        angular.forEach(vm.optionsList, function (opt) {
          vm.optionBooleans[opt.id] = vm.availableOptions.includes(opt.id);
        });
      }

      if (vm.name === 'paint' && vm.showPaintPresets) {
        generatePresetList();
      }

      const coastalOptions = [];
      const unsponsoredOptions = [];
      vm.optionsList.forEach(function (opt) {
        if (isSponsoredComponent(opt)) {
          coastalOptions.push(opt);
        } else {
          unsponsoredOptions.push(opt);
        }
      });

      vm.sponsors = [
        {
          name: 'Coastal',
          slug: 'coastal',
          components: coastalOptions,
          collapsed: false,
          url: 'https://www.coastal-group.com/',
          logo: 'coastal-component-label.png',
        },
      ];
      vm.unsponsoredOptions = unsponsoredOptions;

      // Cannot sort the actual optionsList here because Angular doesn't like it
      // so we do the sort in the template. But we do need to know which one is
      // is first in the list so that we can make it the default, so sort a copy
      // using the same function the template uses.
      if (!vm.defaultOption && !noDefault(vm.name)) {
        setFirstOptionAsDefault();
      }

      if (vm.name === 'paint') {
        vm.nonStandardPaintsOption = {
          allowedIn: null,
          hex: 'linear-gradient(90deg, red, yellow, #37de00, #0095ff, purple)',
          id: 'searchable-paints',
          imageInside: null,
          imageOutside: null,
          name: 'Non-standard paints',
          partCode: '',
          range: '',
          retailerName: 'Non-standard paints',
          cannotBeDefault: true,
          default: false,
          imagesToShow: {hex: true},
        };
      } else {
        vm.nonStandardPaintsOption = null;
      }

      changeRenames();
    }

    function setFirstOptionAsDefault() {
      vm.defaultOption = null;
      if (vm.optionsList && vm.optionsList.length) {
        let optionsListCopy = angular.copy(vm.optionsList);
        optionsListCopy.forEach(function (opt) {
          opt.value = opt.retailerName;
        });
        optionsListCopy = optionsListCopy.sort(sortComponentOptions).filter(function (x) {
          return !x.hide;
        });

        let defaultOption = optionsListCopy.find(function (opt) {
          return opt.default && !opt.cannotBeDefault && vm.availableOptions.includes(opt.id);
        });

        if (!defaultOption) {
          defaultOption = optionsListCopy.find(function (opt) {
            return !opt.cannotBeDefault && vm.availableOptions.includes(opt.id);
          });
        }

        vm.defaultOption = defaultOption ? defaultOption.id : null;
      }
    }

    function generatePresetList() {
      const presets = [];
      vm.optionsList.forEach(function (option) {
        if (!option.range) {
          return;
        }
        const ranges = option.range.split('|');
        ranges.forEach(function (preset) {
          const supplier = preset.split(':')[0];
          if (!presets.includes(supplier)) {
            presets.push(supplier);
          }
        });
      });

      // RAL gets its own section at the top of the select
      vm.paintRanges = [];
      vm.ranges = presets
        .filter(function (x) {
          if (['RAL', 'COVA', 'PANTONE', 'BS', 'Farrow & Ball'].includes(x)) {
            vm.paintRanges.push(x);
            return false;
          }
          return true;
        })
        .sort();
      vm.paintRanges.sort();

      let tickAll = false;
      if (!vm.range) {
        vm.range = 'RAL';
        tickAll = true;
      }
      vm.applyPreset(vm.range, tickAll);
    }

    function applyPreset(selectedPreset, tickAll) {
      if (typeof tickAll === 'undefined') {
        tickAll = true;
      }

      let resetDefault = true;
      vm.availableOptions = [];
      vm.range = selectedPreset;
      vm.optionsList.forEach(function (option) {
        option.hide = true;
        if (tickAll) {
          vm.optionBooleans[option.id] = false;
        }
        if (!option.range) {
          return;
        }
        const ranges = option.range.split('|');
        ranges.forEach(function (preset) {
          const supplier = preset.split(':')[0];
          const name = preset.split(':')[1];
          if (selectedPreset === supplier) {
            vm.renameObject = vm.renameObject || {};
            if (tickAll) {
              vm.renameObject[option.name] = name;
              vm.optionBooleans[option.id] = true;
            }

            // if default in selected preset do not reset
            if (vm.defaultOption === option.id) {
              resetDefault = false;
            }

            option.hide = false;
          }
        });
      });
      checkboxChange();

      if (resetDefault) {
        setFirstOptionAsDefault();
      }
    }

    // When the rename object changes, i.e. the user has renamed a component,
    // check if there are any non-renamable components that need piecewise
    // renaming, e.g. 'Rosewood & White Grain' to 'Rosewood & Whitewash'
    function changeRenames() {
      angular.forEach(vm.optionsList, function (opt) {
        opt.default = opt.retailerName;

        if (vm.name !== 'finish pairs') {
          return;
        }

        if (!opt.canBeRenamed) {
          vm.renameObject = vm.renameObject || {};
          angular.forEach(vm.renameObject, function (to, from) {
            let parts = opt.default.split(' & ');
            parts = parts.map(function (part) {
              return part === from ? to : part;
            });

            opt.default = parts.join(' & ');
          });
        }
      });
    }

    function noDefault(type) {
      return noDefaults.includes(type);
    }

    function setLayout() {
      if (vm.name === 'cill') {
        vm.showDefault = vm.fitCillByDefault;
      } else {
        vm.showDefault = !noDefault(vm.name);
      }
      var finish = ['finish pairs', 'paint'].includes(vm.name);
      vm.containerClass = finish ? 'vs-option--finish' : 'vs-option--hardware';
      vm.showPresetPicker = vm.showPaintPresets && vm.name === 'paint';
      vm.pricingFields = angular.isDefined(pricingFields[vm.name]) ? pricingFields[vm.name] : [];
    }

    function selectAll() {
      angular.forEach(vm.optionsList, function (opt) {
        vm.optionBooleans[opt.id] = !opt.hide;
      });
      angular.forEach(vm.sponsors, function (sponsor) {
        angular.forEach(sponsor.components, function (opt) {
          vm.optionBooleans[opt.id] = !opt.hide;
        });
      });
      if (vm.name === 'paint') {
        vm.paintFinishBool = true;
      }
      checkboxChange();
    }

    function deselectAll() {
      defaultCillValue = !vm.fitCillByDefault ? oldDefaultCillValue : vm.defaultOption;
      angular.forEach(vm.optionsList, function (opt) {
        if (vm.defaultOption !== opt.id || !vm.showDefault) {
          vm.optionBooleans[opt.id] = false;
        }
      });
      angular.forEach(vm.sponsors, function (sponsor) {
        angular.forEach(sponsor.components, function (opt) {
          if (vm.defaultOption !== opt.id || !vm.showDefault) {
            vm.optionBooleans[opt.id] = false;
          }
        });
      });
      checkboxChange();
    }

    function sortComponentOptions(a, b) {
      if (typeof a.value !== 'string' || typeof b.value !== 'string') {
        return 0;
      }

      if (vm.name === 'astragal bar') {
        const aDummy = a.value.includes('Dummy');
        const bDummy = b.value.includes('Dummy');

        if (aDummy !== bDummy) {
          return !aDummy && bDummy ? -1 : 1;
        }
      }

      if (vm.name === 'finish pairs') {
        const aWhite = a.value.startsWith('White Grain');
        const bWhite = b.value.startsWith('White Grain');
        const aArray = a.value.replace(' - ', ' & ').split(' & ');
        const bArray = b.value.replace(' - ', ' & ').split(' & ');

        if (aWhite !== bWhite) {
          return !aWhite && bWhite ? -1 : 1;
        }

        // First sort by the bit before the & alpahbetically
        if (aArray[0].localeCompare(bArray[0]) !== 0) {
          return aArray[0].localeCompare(bArray[0]);
        }

        // Sort by the bit after the &, 'X' -> 'X & White Grain' -> 'X & Smooth White'
        if (aArray[1] && bArray[1]) {
          return -aArray[1].localeCompare(bArray[1]);
        }
        return aArray[1] && !bArray[1] ? 1 : -1;
      }

      return a.value.localeCompare(b.value);
    }

    function isSponsoredComponent(component) {
      const ranges = (component.range || '').split('|');

      if (!ranges.length) {
        return false;
      }

      return ranges.some(function (range) {
        return coastalSponsoredRanges.includes(range);
      });
    }
  }
})();
