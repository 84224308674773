(function () {
  'use strict';

  angular.module('module.settings.retailerProducts').component('ttRetailerProductSingleComponent', {
    templateUrl: 'templates/settings.retailerProducts.singleComponent.component.template.html',
    bindings: {
      option: '<',
      containerClass: '<',
      componentType: '<',
      showComponentPricing: '<',
      pricingFields: '<',
      componentPricing: '=',
      showDefault: '<',
      hideAvailable: '<',
      titleOverride: '@?',
      optionBooleans: '=?',
      paintFinishBool: '=?',
      name: '<',
      renameObject: '=?',
      checkboxChange: '<?',
      defaultOption: '=?',
      radioChange: '<?',
      getComponentOptions: '<',
    },
    controller: ['CurrencyService', controller],
  });

  function controller(CurrencyService) {
    /* jshint validthis: true */
    const $ctrl = this;

    CurrencyService.getSettingsAsync().then((settings) => {
      $ctrl.currencySymbol = settings.currencySymbol;

      switch (settings.measurementType) {
        case 'metric':
          $ctrl.lengthLabel = 'per m';
          $ctrl.lengthKey = 'price per m';
          $ctrl.areaLabel = 'per m²';
          $ctrl.areaKey = 'price per m2';
          break;
        case 'imperial':
          $ctrl.lengthLabel = 'per ft';
          $ctrl.lengthKey = 'price per ft';
          $ctrl.areaLabel = 'per sq. ft';
          $ctrl.areaKey = 'price per ft2';
          break;
        default:
          throw new Error('Unknown measurement type: ' + settings.measurementType);
      }
    });

    $ctrl.updatePiecePricing = updatePiecePricing;

    function updatePiecePricing(pricing) {
      $ctrl.componentPricing['door slab glazing piece'] =
        $ctrl.componentPricing['door slab glazing piece'] || {};
      angular.merge($ctrl.componentPricing['door slab glazing piece'], pricing);
    }
  }
})();
