(function () {
  'use strict';

  angular
    .module('module.settings')
    .service('SettingsService', ['$rootScope', '$q', 'Notification', 'ApiService', service]);

  /**
   * @param $rootScope
   * @param $q
   * @param Notification
   * @param ApiService
   */
  function service($rootScope, $q, Notification, ApiService) {
    var cachedSettingsPromise;
    var updateInProgress = false;

    // refresh settings in login
    $rootScope.$on('authentication.login', function () {
      getSettings(true);
    });

    // clear settings on logout
    $rootScope.$on('authentication.logout', clearSettings);

    return {
      getSettings: getSettings,
      updateSettings: updateSettings,
      getOrderCharges: getOrderCharges,
      updateOrderCharges: updateOrderCharges,
      updatePaymentStages: updatePaymentStages,
      uploadLogo: uploadLogo,
      uploadTermsAndConditions: uploadTermsAndConditions,
      updateContractSettings: updateContractSettings,
    };

    /**
     * Get company settings. Uses cached copy if flush not set.
     *
     * @param {boolean=false} flush
     * @returns {$q}
     */
    function getSettings(flush) {
      if (!cachedSettingsPromise || (flush && !updateInProgress)) {
        updateInProgress = true;
        cachedSettingsPromise = ApiService.get('/company/settings')
          .then(processResponse)
          .then(fireUpdateEvent)
          .finally(function () {
            updateInProgress = false;
          });
      }

      return cachedSettingsPromise;
    }

    /**
     * Update settings, storing new version in cache
     *
     * @param {object} settingsData
     * @returns {$q}
     */
    function updateSettings(settingsData) {
      updateInProgress = true;
      cachedSettingsPromise = ApiService.put('/company/settings', settingsData)
        .then(processResponse)
        .then(
          function (settings) {
            Notification.success('Updated settings');

            return settings;
          },
          function (response) {
            // ensure that rejected promise not cached - next getSettings() needs to make API request
            cachedSettingsPromise = null;
            Notification.error('Failed to update settings');

            return $q.reject(response.data);
          },
        )
        .then(fireUpdateEvent)
        .finally(function () {
          updateInProgress = false;
        });

      return cachedSettingsPromise;
    }

    function getOrderCharges() {
      return ApiService.get('/settings/orderCharges').then(
        function (response) {
          return response.data;
        },
        function () {
          Notification.error('Failed to load order charge settings');
          return $q.reject(null);
        },
      );
    }

    function updateOrderCharges(data) {
      if (data.minPrice === 0) {
        data.minPrice = null;
      }

      return ApiService.put('/settings/orderCharges', data).then(
        function (response) {
          Notification.success('Order charge settings updated');
          return response.data;
        },
        function () {
          Notification.error('Failed to update order charge settings');
          return $q.reject(null);
        },
      );
    }

    function updatePaymentStages(data, notifyOnSuccess, translatedLabel) {
      return ApiService.put('/settings/payment-stages', data).then(
        function (response) {
          if (notifyOnSuccess !== false) {
            Notification.success(translatedLabel + ' updated');
          }
          return response.data;
        },
        function () {
          Notification.error('Failed to update ' + translatedLabel);
          return $q.reject(null);
        },
      );
    }

    function updateContractSettings(contractPdfShowDimensions, cancellationConditions) {
      const data = {
        cancellationConditions: cancellationConditions,
        contractPdfShowDimensions: contractPdfShowDimensions,
      };
      return ApiService.put('/settings/contract-settings', data).then(
        function (response) {
          Notification.success('Contract settings updated');

          return response.data;
        },
        function () {
          Notification.error('Failed to update contract settings');

          return $q.reject(null);
        },
      );
    }

    /**
     * TODO: Needs refactor to use generic uploader based on the PhotoBankUpload service
     *
     * @param {Image} file
     * @returns {$q}
     */
    function uploadLogo(file) {
      var formData = new FormData();
      formData.append('image', file);

      return ApiService.post('/company/logo', formData, {
        headers: {'Content-Type': undefined},
      }).then(
        function (response) {
          Notification.success('Uploaded logo');

          return response.resourceId;
        },
        function () {
          Notification.error('Failed to upload logo');

          return $q.reject(null);
        },
      );
    }

    /**
     * TODO: Needs refactor to use generic uploader based on the PhotoBankUpload service
     *
     * @param {Image} file
     * @returns {$q}
     */
    function uploadTermsAndConditions(file) {
      var formData = new FormData();
      formData.append('file', file);

      return ApiService.post('/company/termsandconditions', formData, {
        headers: {'Content-Type': undefined},
      }).then(
        function (response) {
          Notification.success('Uploaded Terms and Conditions');

          return response.resourceId;
        },
        function () {
          Notification.error('Failed to upload Terms and Conditions');

          return $q.reject(null);
        },
      );
    }

    /**
     * Process the settings response
     *
     * @param {object} response
     * @returns {object}
     */
    function processResponse(response) {
      if (response.data.company.metadata.subdomain !== null) {
        response.data.company.metadata.subdomainReadonly = true;
      }

      return response.data.company;
    }

    /**
     * Notify listeners that settings may have changed
     *
     * @param {object} settings
     * @returns {object}
     */
    function fireUpdateEvent(settings) {
      $rootScope.$emit('settings.update', settings);

      return settings;
    }

    /**
     * Clear settings cache
     */
    function clearSettings() {
      cachedSettingsPromise = null;
      updateInProgress = false;
      fireUpdateEvent(null);
    }
  }
})();
