(function () {
  'use strict';

  angular
    .module('module.settings')
    .service('CurrencyService', ['$rootScope', 'SettingsService', service]);

  const defaultSettings = {
    salesTaxType: 'VAT',
    salesTaxRate: '0.20',
    currency: 'pound',
    currencySymbol: '£',
    timezone: 'Europe/London',
    hingeAnnotationDirection: 'hinge',
    measurementType: 'metric',
    dimensionsUnit: 'Inches and Decimals',
    fractionalPrecision: '1/32',
  };

  function service($rootScope, SettingsService) {
    let settings;

    resetSettings();

    $rootScope.$on('settings.update', function (event, settings) {
      setFromCompanySettings(settings);
    });

    SettingsService.getSettings().then(setFromCompanySettings);

    return {
      getSettingsAsync: getAsync,
      setSettings: setSettings,
      getSettings: getSettings,
    };

    function setSettings(newSettings) {
      settings = {
        salesTaxType: newSettings.salesTaxType,
        salesTaxRate: newSettings.salesTaxRate,
        currency: newSettings.currency,
        currencySymbol: newSettings.currencySymbol,
        timezone: newSettings.timezone,
        hingeAnnotationDirection: newSettings.hingeAnnotationDirection,
        measurementType: newSettings.measurementType,
        dimensionsUnit: newSettings.dimensionsUnit,
        fractionalPrecision: newSettings.fractionalPrecision,
      };
    }

    function getAsync() {
      return SettingsService.getSettings().then((settings) => {
        setFromCompanySettings(settings);

        return getSettings();
      });
    }

    function getSettings() {
      return Object.assign({}, settings);
    }

    function setFromCompanySettings(companySettings) {
      if (companySettings === null) {
        resetSettings();

        return;
      }

      setSettings(companySettings.settings.currency);
    }

    function resetSettings() {
      settings = angular.copy(defaultSettings);
    }
  }
})();
