(function () {
  'use strict';

  angular
    .module('module.authentication')
    .factory('CompanySubdomainService', ['$location', '$q', 'ApiService', service]);

  function service($location, $q, ApiService) {
    var defaultSubdomain = 'tommy',
      defaultSubdomainDetails = {
        company: 'Tommy',
        logo: 'images/tt-logo-black.png',
        subdomain: defaultSubdomain,
        locale: 'UK',
      };

    return {
      getSubdomain: getSubdomain,
      getSubdomainDetails: getSubdomainDetails,
      isDefaultSubdomain: isDefaultSubdomain,
    };

    /**
     * Get user subdomain data
     *
     * @returns {Promise}
     */
    function getSubdomainDetails() {
      if (isDefaultSubdomain()) {
        return $q.when(defaultSubdomainDetails);
      }

      return ApiService.get('/subdomains/' + getSubdomain()).then(function (response) {
        return response.data.subdomain;
      });
    }

    /**
     * Get company subdomain from hostname
     *
     * @returns {string}
     */
    function getSubdomain() {
      return $location.host().split('.').shift();
    }

    /**
     * Determine if on default subdomain
     *
     * @returns {boolean}
     */
    function isDefaultSubdomain() {
      return getSubdomain() === defaultSubdomain;
    }
  }
})();
