(function () {
  'use strict';

  angular.module('module.legal').config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider.state('legal', {
      abstract: true,
      url: '/legal',
      views: {
        'navigation@': {
          controller: 'controller.navigation',
          templateUrl: 'templates/navigation.template.html',
          resolve: {
            dataUser: [
              'AuthenticationService',
              function (AuthenticationService) {
                return AuthenticationService.getUser();
              },
            ],
          },
        },
        'content@': {
          templateUrl: 'templates/legal.template.html',
        },
      },
      ncyBreadcrumb: {
        label: 'Legal',
      },
    });

    $stateProvider.state('legal.termsConditions', {
      url: '/terms-and-conditions',
      resolve: {
        termsUrl: [
          'TermsAndConditionsService',
          function (TermsAndConditionsService) {
            return TermsAndConditionsService.getCurrentVersion().then(function (response) {
              return response.data.downloadUrl;
            });
          },
        ],
      },
      onEnter: [
        'termsUrl',
        '$window',
        function (termsUrl, $window) {
          $window.location.replace(termsUrl);
        },
      ],
    });

    $stateProvider.state('legal.privacyPolicy', {
      url: '/privacy-policy',
      templateUrl: 'templates/legal.privacyPolicy.template.html',
      ncyBreadcrumb: {
        label: 'Privacy Policy',
      },
      data: {
        meta: {
          titleSuffix: '| Legal | Privacy Policy',
        },
      },
    });

    $stateProvider.state('legal.cookiePolicy', {
      url: '/cookie-policy',
      templateUrl: 'templates/legal.cookiePolicy.template.html',
      ncyBreadcrumb: {
        label: 'Cookie Policy',
      },
      data: {
        meta: {
          titleSuffix: '| Legal | Cookie Policy',
        },
      },
    });
  }
})();
