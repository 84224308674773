(function () {
  'use strict';

  angular.module('module.settings.retailerGlass').directive('ttSettingsGlassTab', directive);

  /**
   * Init and configure directive
   */
  function directive() {
    var validTabs = [
      'glassSupply',
      'glassMarkup',
      'sealedUnits',
      'spacerBar',
      'solarControl',
      'selfClean',
      'glazing',
      // 'decorative', TODO: re-enable if decorative features return
      'texture',
    ];

    return {
      restrict: 'A',
      templateUrl: getTemplate,
      controllerAs: 'tabVm',
      scope: {
        settings: '=',
      },
      controller: [controller],
      bindToController: true,
    };

    /**
     * Validate and build path to glass settings template
     *
     * @param elem
     * @param attr
     * @returns {string}
     */
    function getTemplate(elem, attr) {
      if (validTabs.indexOf(attr.tab) === -1) {
        throw 'Unknown tab name "' + attr.tab + '"';
      }

      return 'templates/settings.retailerGlass.tab.' + attr.tab + '.template.html';
    }

    /**
     * Init controller
     */
    function controller() {
      /* jshint validthis: true */
      var vm = this;

      vm.glassSettings = vm.settings.settings;
      vm.glassSettings.glassMarkupPercentage = parseFloat(vm.glassSettings.glassMarkupPercentage);
    }
  }
})();
