(function () {
  'use strict';

  angular.module('module.components').component('ttPaymentStages', {
    templateUrl: 'templates/components.paymentStages.template.html',
    bindings: {
      stages: '=',
      totalPercentage: '=',
      allowEdit: '<',
    },
    controller: ['TranslationService', controller],
  });

  function controller(TranslationService) {
    /* jshint validthis: true */
    var vm = this;

    vm.addPaymentStage = addPaymentStage;
    vm.recalculateTotalPercentage = recalculateTotalPercentage;
    vm.deletePaymentStage = deletePaymentStage;
    vm.totalPercentage = 0;
    vm.sortableOptions = {
      delay: 200,
      delayOnTouchOnly: true,
      filter: '.do-not-drag',
      preventOnFilter: false,
    };

    recalculateTotalPercentage();

    function recalculateTotalPercentage() {
      var totalPercentage = 0;

      if (!vm.stages) {
        vm.stages = [];
      }

      vm.stages.forEach(function (stage) {
        totalPercentage += stage.percentage || 0;
      });

      vm.totalPercentage = totalPercentage;
    }

    function addPaymentStage() {
      if (!vm.stages) {
        vm.stages = [];
      }

      vm.stages.push({
        label: 'New ' + TranslationService.translate('stage payment'),
        percentage: Math.max(0, 100 - vm.totalPercentage),
      });
      recalculateTotalPercentage();
    }

    function deletePaymentStage(index) {
      vm.stages.splice(index, 1);
      recalculateTotalPercentage();
    }
  }
})();
