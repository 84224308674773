(function () {
  'use strict';

  angular.module('module.navigation').directive('ttOnboarding', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/navigation.onboarding.directive.template.html',
      controllerAs: 'onboardingVm',
      scope: true,
      controller: ['OnboardingService', controller],
    };

    /**
     * @param AuthenticationService
     * @param $location
     * @param $rootScope
     * @param OnboardingService
     */
    function controller(OnboardingService) {
      /* jshint validthis: true */
      var vm = this;

      vm.onboardingItems = [];
      vm.currentItem = null;

      OnboardingService.setOnboardingListener(updateOnboarding);

      /**
       * Onboarding update callback
       *
       * @param onboardingItems
       */
      function updateOnboarding(onboardingItems) {
        vm.onboardingItems = onboardingItems;
        vm.currentItem = getCurrentItem(onboardingItems);
      }

      /**
       * Get currently active onboarding item (first that is incomplete)
       *
       * @param {Array} onboardingItems
       * @returns {object|null}
       */
      function getCurrentItem(onboardingItems) {
        for (var i = 0; i < onboardingItems.length; i++) {
          if (!onboardingItems[i].completed) {
            return onboardingItems[i];
          }
        }

        return null;
      }
    }
  }
})();
