(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('RetailersService', [
      '$q',
      '$httpParamSerializer',
      'Notification',
      'ApiService',
      'ProductsService',
      service,
    ]);

  function service($q, $httpParamSerializer, Notification, ApiService, ProductsService) {
    return {
      createRetailer: createRetailer,
      retrieveRetailer: retrieveRetailer,
      updateRetailer: updateRetailer,
      listRetailers: listRetailers,
      retrieveRetailerForOwner: retrieveRetailerForOwner,
      updatePricingPlan: updatePricingPlan,
      updateApiAccess: updateApiAccess,
      updateRetailerPricing: updateRetailerPricing,
      resetTermsAndConditions: resetTermsAndConditions,
    };

    /**
     * @param {object} retailer
     * @returns {$Q}
     */
    function createRetailer(retailer) {
      return ApiService.post('/companies/retailers', retailer).then(
        function () {
          Notification.success('Created retailer');
        },
        function (response) {
          Notification.error('Failed to create retailer');

          return $q.reject(response.data.error || []);
        },
      );
    }

    /**
     * @param {string} retailerId
     * @returns {$Q}
     */
    function retrieveRetailer(retailerId) {
      return ApiService.get('/companies/retailers/' + retailerId).then(function (response) {
        // TODO: This should be implemented in the API
        var retailer = response.data.company;
        retailer.metadata.discount = parseInt(retailer.metadata.discount);

        return retailer;
      });
    }

    /**
     *
     * @param {string} retailerId
     * @returns {PromiseLike<{retailer: *, pricingPlans: *}> | Promise<{retailer: *, pricingPlans: *}>}
     */
    function retrieveRetailerForOwner(retailerId) {
      return ApiService.get('/companies/retailers/' + retailerId).then(function (response) {
        return {
          retailer: response.data.company,
          pricingPlans: response.data.pricingPlans,
        };
      });
    }

    /**
     * @param {object} retailer
     * @returns {$Q}
     */
    function updateRetailer(retailer) {
      return ApiService.put('/companies/retailers/' + retailer.id, retailer).then(
        function () {
          Notification.success('Updated retailer');
        },
        function () {
          Notification.error('Failed to update retailer');

          return $q.reject(null);
        },
      );
    }

    function updateRetailerPricing(retailer, settings) {
      return ApiService.put(
        '/companies/retailers/' + retailer.id + '/productPricing',
        settings,
      ).then(
        function () {
          Notification.success('Updated product pricing');
        },
        function () {
          Notification.error('Failed to update product pricing');

          return $q.reject(null);
        },
      );
    }

    function updatePricingPlan(retailer, plan) {
      var request = {
        pricingPlan: plan.code,
        maxActiveUsers: plan.maxUsers,
      };

      return ApiService.put('/companies/retailers/' + retailer.id + '/pricingPlan', request).then(
        function () {
          Notification.success('Updated plan');
        },
        function () {
          Notification.error('Failed to update plan');

          return $q.reject(null);
        },
      );
    }

    function updateApiAccess(retailer, apiAccess) {
      var request = {
        apiAccess: apiAccess,
      };

      return ApiService.put('/companies/retailers/' + retailer.id + '/apiAccess', request).then(
        function () {
          Notification.success('Updated API Access');
        },
        function () {
          Notification.error('Failed to update API Access');

          return $q.reject(null);
        },
      );
    }

    /**
     * @returns {$q}
     */
    function listRetailers(page, limit, searchTerm) {
      var params = {
        page: page == null ? 1 : page,
      };

      if (limit != null) {
        params.limit = limit;
      }

      if (!!searchTerm) {
        params.searchTerm = searchTerm;
      }

      var serialisedParams = $httpParamSerializer(params);

      return ApiService.get('/companies/retailers?' + serialisedParams).then(function (response) {
        return {
          retailers: response.data.companies,
          totalPages: response.data.totalPages,
        };
      });
    }

    /**
     *
     * @param {string} retailerId
     * @returns {$q}
     */
    function resetTermsAndConditions(retailerId) {
      return ApiService.delete('/user-terms-and-conditions/' + retailerId).then(
        function () {
          Notification.success('Reset terms and conditions');
        },
        function () {
          Notification.error('Failed to reset terms and conditions');

          return $q.reject(null);
        },
      );
    }
  }
})();
