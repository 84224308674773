(function () {
  'use strict';

  angular.module('module.sales').component('ttOrderPart', {
    templateUrl: 'templates/sales.orders.orderPart.component.template.html',
    controller: [
      '$timeout',
      '$q',
      'OrderPartApiService',
      'CurrencyService',
      'SettingsService',
      controller,
    ],
    bindings: {
      order: '<',
      orderPart: '<',
      onOrderPartUpdated: '<',
    },
  });

  var status = {
    new: 'new',
    ready: 'ready',
    queued: 'queued',
  };

  function controller($timeout, $q, OrderPartApiService, CurrencyService, SettingsService) {
    /* jshint validthis: true */
    var vm = this,
      updateOrderPartTimeoutPromise;

    SettingsService.getSettings().then(function (settings) {
      vm.locale = settings.metadata.locale;
    });

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    vm.sendOrder = sendOrder;
    vm.deliveredOrder = deliveredOrder;
    vm.onDeliveryDetailsUpdated = onDeliveryDetailsUpdated;

    CurrencyService.getSettingsAsync().then((data) => {
      vm.salesTaxType = data.salesTaxType;
      vm.currencySymbol = data.currencySymbol;
    });

    function onInit() {
      updateOrderPart(vm.orderPart);
    }

    function onDestroy() {
      cancelOrderPartUpdateTimeout();
    }

    /**
     * Send order to manufacturer
     */
    function sendOrder() {
      OrderPartApiService.send(vm.orderPart).then(updateOrderPart);
    }

    /**
     * Retailer mark order part as delivered
     */
    function deliveredOrder() {
      OrderPartApiService.delivered(vm.orderPart).then(updateOrderPart);
    }

    /**
     * Set order status to queued and update following a change to delivery details
     */
    function onDeliveryDetailsUpdated() {
      vm.orderPart.status = status.queued;
      vm.canUpdateDelivery = false;
      updateOrderPart();
    }

    /**
     * Updates the order, polling if it is required
     */
    function updateOrderPart(orderPart) {
      cancelOrderPartUpdateTimeout();
      var orderPartPromise = orderPart
        ? $q.when(orderPart)
        : OrderPartApiService.get(vm.orderPart.id);

      orderPartPromise.then(function (orderPart) {
        if (orderPartNeedsPolled(orderPart)) {
          updateOrderPartTimeoutPromise = $timeout(updateOrderPart, 2000);

          return;
        }

        vm.orderPart = orderPart;
        setCanUpdateDelivery();
        vm.onOrderPartUpdated();
      });
    }

    /**
     * Cancel any running order update
     */
    function cancelOrderPartUpdateTimeout() {
      if (updateOrderPartTimeoutPromise) {
        $timeout.cancel(updateOrderPartTimeoutPromise);
      }
    }

    /**
     * Check if update delivery modal should be available
     */
    function setCanUpdateDelivery() {
      vm.canUpdateDelivery = [status.new, status.ready].indexOf(vm.orderPart.status) !== -1;
    }

    /**
     * @param {Object} orderPart
     *
     * @return {boolean}
     */
    function orderPartNeedsPolled(orderPart) {
      return status.queued === orderPart.status;
    }
  }
})();
