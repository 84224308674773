(function () {
  'use strict';

  angular.module('module.sales').directive('ttArchiveSlice', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.archive.slice.directive.template.html',
        controllerAs: 'sliceVm',
        scope: {
          quote: '=',
          customer: '=',
        },
        controller: [
          '$state',
          '$rootScope',
          '$window',
          'QuotesService',
          'CustomerQuotesArchiveApiService',
          'CurrencyService',
          controller,
        ],
        bindToController: true,
      };
    },
  ]);

  function controller(
    $state,
    $rootScope,
    $window,
    QuotesService,
    CustomerQuotesArchiveApiService,
    CurrencyService,
  ) {
    /* jshint validthis: true */
    var vm = this;
    vm.reloadArchiveAction = reloadArchiveAction;
    vm.onlyOwnQuotesVisible = $rootScope.onlyOwnQuotesVisible;
    vm.isProcessing =
      vm.quote.metadata.status === 'finalising' &&
      !vm.quote.metadata.documents.quote.pdfGenerationFailed;
    vm.showActions = false;
    CurrencyService.getSettingsAsync().then((data) => {
      vm.currencySymbol = data.currencySymbol;
    });

    function reloadArchiveAction() {
      if (
        $window.confirm(
          'Are you sure you want to restore archived quote with reference ' +
            vm.quote.metadata.reference +
            '?',
        )
      ) {
        CustomerQuotesArchiveApiService.getArchiveList(vm.customer).then(function (response) {
          var archiveLength = response.length;
          var hashLocation = archiveLength <= 1 ? 'quotes' : 'archive';
          QuotesService.restoreQuote(vm.quote);
          $state.go(
            'sales.customers.retrieve',
            {customerId: vm.customer.id, '#': hashLocation, hashLocation: hashLocation},
            {reload: true},
          );
        });
      }
    }
  }
})();
