(function () {
  'use strict';

  angular.module('module.settings.retailerGlass').directive('ttSettingsGlassTabFeature', directive);

  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/settings.retailerGlass.tab.feature.directive.template.html',
      controllerAs: '$ctrl',
      scope: {
        featureSettings: '=',
        hideOwnCosts: '<?',
        hideManufacturerCosts: '<?',
      },
      controller: ['CurrencyService', controller],
      bindToController: true,
    };
  }

  function controller(CurrencyService) {
    /* jshint validthis: true */
    const $ctrl = this;

    CurrencyService.getSettingsAsync().then((settings) => {
      switch (settings.measurementType) {
        case 'metric':
          $ctrl.areaLabel = 'per m²';
          $ctrl.areaKey = 'perM2Cost';
          $ctrl.manufacturerAreaKey = 'manufacturerPerM2Cost';
          $ctrl.minArea = '0.3m²';
          break;
        case 'imperial':
          $ctrl.areaLabel = 'per sq. ft';
          $ctrl.areaKey = 'perFt2Cost';
          $ctrl.manufacturerAreaKey = 'manufacturerPerFt2Cost';
          $ctrl.minArea = '3 sq. ft';
          break;
        default:
          throw new Error('Unknown measurement type: ' + settings.measurementType);
      }

      $ctrl.currencySymbol = settings.currencySymbol;
    });
  }
})();
