(function () {
  'use strict';

  angular.module('module.visualiser').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('visualiser', {
          views: {
            'navigation@': {
              controller: 'controller.navigation',
              templateUrl: 'templates/navigation.template.html',
              resolve: {
                dataUser: [
                  'AuthenticationService',
                  function (AuthenticationService) {
                    return AuthenticationService.getUser();
                  },
                ],
              },
            },
          },
        })

        .state('visualiser.dash', {
          url: '/visualiser/:quoteId',
          views: {
            'content@': {
              templateUrl: 'templates/visualiser.dash.template.html',
              controller: 'controller.visualiser.dash',
              controllerAs: 'dashVm',
            },
          },
          resolve: {
            quote: [
              '$stateParams',
              'QuotesService',
              function ($stateParams, QuotesService) {
                return QuotesService.retrieveQuote($stateParams.quoteId);
              },
            ],
          },
          ncyBreadcrumb: {
            label: "{{'Visualiser' | translate}}",
            parent: 'sales.quotes.retrieve({quoteId: quote.id, "#": "Visualiser"})',
          },
          data: {
            meta: {
              titleSuffix: '| Visualiser',
            },
          },
        })

        .state('visualiser.compositor', {
          url: '/visualiser/:quoteId/compositor/{mode:new|edit}/:imageId',
          views: {
            'content@': {
              templateUrl: 'templates/visualiser.compositor.template.html',
              controller: 'controller.visualiser.compositor',
              controllerAs: 'compositorVm',
              resolve: {
                completedImage: [
                  '$stateParams',
                  'CompletedImageService',
                  function ($stateParams, CompletedImageService) {
                    if ($stateParams.mode === 'new') {
                      return null;
                    }

                    return CompletedImageService.getImage(
                      $stateParams.quoteId,
                      $stateParams.imageId,
                    );
                  },
                ],
                photoBankImage: [
                  '$stateParams',
                  'PhotoBankService',
                  'completedImage',
                  function ($stateParams, PhotoBankService, completedImage) {
                    // use completedImage to get photo bank image if editing
                    if (completedImage !== null) {
                      return completedImage.photoBankImage;
                    }

                    return PhotoBankService.getImage($stateParams.quoteId, $stateParams.imageId);
                  },
                ],
                quote: [
                  '$stateParams',
                  'QuotesService',
                  function ($stateParams, QuotesService) {
                    return QuotesService.retrieveQuote($stateParams.quoteId);
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            label: 'Compositor',
            parent: 'visualiser.dash({quoteId: quote.id,itemActiveName:itemActiveName})',
          },
          data: {
            meta: {
              titleSuffix: '| Visualiser | Compositor',
              viewport:
                'user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width,height=device-height,target-densitydpi=device-dpi',
            },
          },
        });
    },
  ]);

  angular.module('module.visualiser').run([
    '$window',
    function ($window) {
      $window.DragDropPolyfill.Initialize();
    },
  ]);
})();
