(function () {
  'use strict';

  angular
    .module('module.settings.pricingAndQuoting')
    .directive('ttPricingSettingsMarkup', directive);

  /**
   * Init directive
   */
  function directive() {
    return {
      restrict: 'E',
      templateUrl: 'templates/settings.pricingAndQuoting.pricing.markup.directive.template.html',
      controllerAs: 'markupVm',
      scope: {
        settings: '=',
        initialValue: '=',
        updateCheckbox: '=',
        updateInput: '=',
      },
      controller: controller,
      bindToController: true,
    };
  }
  function controller() {
    /* jshint validthis: true */
    var vm = this;

    vm.initialValue = vm.initialValue;
    var firstJT =
      (vm.settings.productSettings &&
        vm.settings.productSettings[0] &&
        vm.settings.productSettings[0].markupPerJobType) ||
      {};

    vm.addAMarkup = vm.settings.useMarkup !== 'no';
    vm.sameForAllProducts = vm.settings.useMarkup === 'yes';

    // If we're varing by job type then we have useMarkup set to 'products' but
    // they may all be identical
    if (vm.settings.useMarkupPerJobType) {
      vm.sameForAllProducts = true;
      vm.settings.productSettings.forEach(function (product, index) {
        if (JSON.stringify(product.markupPerJobType) !== JSON.stringify(firstJT)) {
          vm.sameForAllProducts = false;
        }
      });
    }

    vm.updateCheckbox = false;
    vm.updateInput = false;
    vm.onCheckboxChange = function (modalName) {
      vm.updateCheckbox = true;
      checkForConfirmation(modalName);
      update();
    };
    vm.onInputChange = function () {
      vm.updateInput = true;
      update();
    };
    function checkForConfirmation(modalName) {
      if (vm.updateCheckbox && vm.updateInput) {
        showConfirmation(modalName);
      }
    }

    function showConfirmation(modalName) {
      var confirmation = window.confirm(
        'You have unsaved changes. Are you sure you want to proceed?',
      );
      if (!confirmation) {
        vm.updateInput = true;
        if (modalName === 'sameForAllProducts') {
          vm.sameForAllProducts = !vm.sameForAllProducts;
        }
        if (modalName === 'varyByJobType') {
          vm.settings.useMarkupPerJobType = !vm.settings.useMarkupPerJobType;
        }
        if (modalName === 'addAMarkup') {
          vm.addAMarkup = !vm.addAMarkup;
        }
        return;
      } else {
        vm.updateInput = false;
        for (let index = 0; index < vm.settings.productSettings.length; index++) {
          vm.settings.productSettings[index].markupPerJobType = angular.copy(
            vm.initialValue.productSettings[index].markupPerJobType,
          );
          vm.settings.productSettings[index].markup = angular.copy(
            vm.initialValue.productSettings[index].markup,
          );
        }
        vm.settings.markup = angular.copy(vm.initialValue.markup);
      }
    }

    function update() {
      // Set the tri-state
      vm.settings.useMarkup = 'no';
      if (vm.addAMarkup) {
        vm.settings.useMarkup = 'products';
        if (vm.sameForAllProducts && !vm.settings.useMarkupPerJobType) {
          vm.settings.useMarkup = 'yes';
        }
      }

      if (vm.settings.useMarkup === 'products') {
        vm.settings.markup = vm.settings.markup ? vm.settings.markup : 0;
      }

      vm.settings.productSettings.forEach(function (product, index) {
        // Reset product markups if not relevant
        if (vm.settings.useMarkupPerJobType) {
          firstJT =
            (vm.settings.productSettings &&
              vm.settings.productSettings[0] &&
              vm.settings.productSettings[0].markupPerJobType) ||
            {};
          if (!vm.updateInput) {
            Object.keys(product.markupPerJobType).forEach(function (key) {
              product.markupPerJobType[key] = angular.copy(
                vm.initialValue.productSettings[index].markupPerJobType[key],
              );
            });
          }
        }

        // If varying by job type but not by product, copy to all products
        if (vm.settings.useMarkupPerJobType && vm.sameForAllProducts && index !== 0) {
          product.markupPerJobType = JSON.parse(JSON.stringify(firstJT));
        }
      });
    }
  }
})();
