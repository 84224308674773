(function () {
  'use strict';
  angular
    .module('module.translations')
    .filter('translate', ['TranslationService', '$rootScope', filter]);

  function filter(TranslationService, $rootScope) {
    const translationPromise = TranslationService.loadTranslations();
    const translationData = new Map();

    // wipe translation cache on settings update (possible locale change)
    $rootScope.$on('settings.update', () => translationData.clear());

    translateFilter.$stateful = true;
    function translateFilter(input) {
      if (!input) {
        return input;
      }

      // if already in local cache return
      if (translationData.has(input)) {
        return translationData.get(input);
      }

      // fetch value if missing
      translationPromise.then(() => {
        translationData.set(input, TranslationService.translate(input));
      });

      // return input value until translations are ready
      return input;
    }

    return translateFilter;
  }
})();
