(function () {
  'use strict';

  angular
    .module('module.authentication')
    .controller('controller.authentication.register', [
      '$rootScope',
      '$stateParams',
      '$state',
      'AuthenticationService',
      'CompanySubdomainService',
      'BreadcrumbsService',
      'registrationData',
      'TermsAndConditionsService',
      controller,
    ]);

  function controller(
    $rootScope,
    $stateParams,
    $state,
    AuthenticationService,
    CompanySubdomainService,
    BreadcrumbsService,
    registrationData,
    TermsAndConditionsService,
  ) {
    /* jshint validthis: true */
    var vm = this;
    var zxcvbn = window.zxcvbn;
    vm.updatePasswordStrengthBar = updatePasswordStrengthBar;
    var strengthBarElement = angular.element('.password-strength-bar');
    var strengthTextElement = angular.element('.password-strength-text');

    vm.register = register;

    BreadcrumbsService.hideBreadcrumbs();
    $rootScope.bodyClass = 'login';

    vm.email = registrationData.metadata.email;

    vm.terms = {
      require: registrationData.metadata.mustSignTerms,
      url: registrationData.metadata.termsAndConditionsUrl,
      accept: false,
    };

    vm.acceptCurrentTermsandConditions = false;
    vm.showPassword = false;

    // TODO: move to directive
    CompanySubdomainService.getSubdomainDetails().then(function (details) {
      vm.title = details.company;
      vm.logo = details.logo;
    });

    init();

    function init() {
      TermsAndConditionsService.getCurrentVersion().then(function (terms) {
        vm.termsList = terms;
      });
    }

    /**
     * Confirm registration of user
     */
    function register() {
      AuthenticationService.confirmRegistrationDetails(
        $stateParams.token,
        vm.password,
        vm.acceptCurrentTermsandConditions,
      ).then(
        function () {
          $state.go('dashboard');
        },
        function (error) {
          const message =
            error.status === 404
              ? 'Registration token is invalid or expired. Please contact support.'
              : 'There has been an issue processing your registration. Please contact support.';

          $state.go('authentication.signin', {message});
        },
      );
    }

    function updatePasswordStrengthBar() {
      if (vm.password) {
        vm.passwordStrength = zxcvbn(vm.password);
        switch (vm.passwordStrength.score) {
          case 0:
            // Very weak
            strengthBarElement
              .removeClass('password-strength-bar-medium password-strength-bar-strong')
              .addClass('password-strength-bar-weak');
            strengthTextElement.text('Very Weak').css('color', 'red');
            break;
          case 1:
            // Weak
            strengthBarElement
              .removeClass('password-strength-bar-medium password-strength-bar-strong')
              .addClass('password-strength-bar-weak');
            strengthTextElement.text('Weak').css('color', 'red');
            break;
          case 2:
            // Medium
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-strong')
              .addClass('password-strength-bar-medium');
            strengthTextElement.text('Medium').css('color', '#af6700');
            break;
          case 3:
            // Strong
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-medium')
              .addClass('password-strength-bar-strong');
            strengthTextElement.text('Strong').css('color', '#27ae60');
            break;
          case 4:
            // Very strong
            strengthBarElement
              .removeClass('password-strength-bar-weak password-strength-bar-medium')
              .addClass('password-strength-bar-strong');
            strengthTextElement.text('Very Strong').css('color', '#27ae60');
            break;
          default:
            // Handle unexpected score
            strengthBarElement.removeClass(
              'password-strength-bar-medium password-strength-bar-strong',
            );
            strengthTextElement.text('').css('color', '');
            break;
        }
      }
    }
  }
})();
