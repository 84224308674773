(function () {
  'use strict';

  angular.module('module.sales').directive('ttQuoteSlice', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.quotes.slice.directive.template.html',
        controllerAs: 'sliceVm',
        scope: {
          quote: '=',
          customer: '<',
        },
        controller: ['$rootScope', 'CurrencyService', controller],
        bindToController: true,
      };
    },
  ]);

  function controller($rootScope, CurrencyService) {
    /* jshint validthis: true */
    var vm = this;
    vm.onlyOwnQuotesVisible = $rootScope.onlyOwnQuotesVisible;
    CurrencyService.getSettingsAsync().then((data) => {
      vm.currencySymbol = data.currencySymbol;
    });

    vm.isProcessing =
      vm.quote.metadata.status === 'finalising' &&
      !vm.quote.metadata.documents.quote.pdfGenerationFailed;
    vm.showActions = false;
  }
})();
