(function () {
  'use strict';

  angular
    .module('module.retailers')
    .factory('OwnerPartsShopProductsApiService', ['$q', 'ApiService', 'Notification', service]);

  function service($q, ApiService, Notification) {
    return {
      listForRetailer: listForRetailer,
    };

    function listForRetailer(retailerId) {
      return ApiService.get('/companies/retailers/' + retailerId + '/custom-item-templates').then(
        function (response) {
          return response.data;
        },
      );
    }
  }
})();
