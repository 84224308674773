(function () {
  'use strict';

  angular.module('module.settings.company').controller('controller.settings.company.logo', [
    '$scope',
    '$state',
    '$timeout',
    'dataSettings',
    'SettingsService',
    function ($scope, $state, $timeout, dataSettings, SettingsService) {
      $scope.company = dataSettings;
      $scope.logoActionLabel =
        $scope.company.metadata.logoStatus === 'none' ? 'Upload Logo' : 'Update Logo';

      const MAX_FILE_SIZE = 5 * 1024 * 1024;
      const ALLOWED_FILE_TYPE = 'image/png';

      $scope.checkLogo = function () {
        $timeout(function () {
          SettingsService.getSettings(true).then(function (settingsData) {
            $scope.company.metadata.logo = settingsData.metadata.logo;

            if (settingsData.metadata.logoStatus === 'processing') {
              $scope.logoActionLoading = true;
              $scope.checkLogo();
            } else {
              $scope.logoActionLoading = false;
              $scope.logoActionLabel = 'Update Logo';
            }
          });
        }, 5000);
      };

      $scope.uploadLogo = function (input) {
        const file = input.files[0];

        $scope.$apply(function () {
          $scope.fileError = null;

          if (!file) {
            return;
          }

          if (file.size > MAX_FILE_SIZE) {
            $scope.fileError = 'The file size exceeds the 5MB limit.';
            input.value = '';
            return;
          }

          if (file.type !== ALLOWED_FILE_TYPE) {
            $scope.fileError = 'Please upload a PNG file.';
            input.value = '';
            return;
          }

          $scope.fileError = null;
          $scope.logoActionLabel = 'Uploading...';

          SettingsService.uploadLogo(file).then(function () {
            $scope.company.metadata.logoStatus = 'processing';
            $scope.company.metadata.logo = null;
            $scope.logoActionLoading = true;
            $scope.checkLogo();
          });
        });
      };

      if ($scope.company.metadata.logoStatus === 'processing') {
        $scope.logoActionLabel = 'Uploading...';
        $scope.logoActionLoading = true;
        $scope.checkLogo();
      }
    },
  ]);
})();
