(function () {
  'use strict';

  angular.module('module.settings.partsShop').component('ttPartsShopSlice', {
    templateUrl: 'templates/settings.partsShop.slice.component.template.html',
    controller: ['$window', '$state', 'PartsShopApiService', 'CurrencyService', controller],
    bindings: {
      item: '<',
      onDelete: '<',
      onCopy: '<',
    },
  });

  function controller($window, $state, ApiService, CurrencyService) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.delete = deleteItem;
    $ctrl.copy = copyItem;
    $ctrl.onClick = onClick;

    CurrencyService.getSettingsAsync().then((currency) => {
      $ctrl.currencySymbol = currency.currencySymbol;
    });

    function deleteItem() {
      if ($window.confirm('Are you sure you want to delete this?')) {
        ApiService.remove($ctrl.item.id).then(() => $ctrl.onDelete($ctrl.item));
      }
    }

    function copyItem() {
      ApiService.copy($ctrl.item.id).then($ctrl.onCopy);
    }

    function onClick() {
      $state.go('settings.partsShop.update', {id: $ctrl.item.id});
    }
  }
})();
