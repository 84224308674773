(function () {
  'use strict';

  angular.module('module.retailers').component('ttLibraryPartsShopProductsPicker', {
    templateUrl:
      'templates/retailers.ownerView.libraryPartsShopProductsPicker.component.template.html',
    controller: ['PartsShopApiService', controller],
    bindings: {
      onChange: '<',
      search: '<',
      retailer: '<',
    },
  });

  function controller(PartsShopApiService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.onChangeSelected = onChangeSelected;
    $ctrl.products = null;

    PartsShopApiService.listProductLibrary($ctrl.retailer).then(function (products) {
      $ctrl.products = products;
    });

    $ctrl.selectionRequired = true;

    function onChangeSelected() {
      var selectedProducts = $ctrl.products.reduce(function (selected, product) {
        if (product.selected) {
          selected.push(product.id);
        }

        return selected;
      }, []);

      $ctrl.selectionRequired = selectedProducts.length === 0;
      $ctrl.onChange(selectedProducts);
    }
  }
})();
