(function () {
  'use strict';

  angular
    .module('module.settings.preferences')
    .factory('CurrencyApiService', [
      '$q',
      'Notification',
      'ApiService',
      'SettingsService',
      service,
    ]);

  function service($q, Notification, ApiService, SettingsService) {
    return {
      update: update,
    };

    /**
     * Updates currency settings.
     *
     * @param {Object} currencySettings
     *
     * @returns {$q}
     */
    function update(currencySettings) {
      const data = {
        salesTaxType: currencySettings.salesTaxType,
        salesTaxRate: currencySettings.salesTaxRate,
        currency: currencySettings.currency,
        timezone: currencySettings.timezone,
        hingeAnnotationDirection: currencySettings.hingeAnnotationDirection,
        measurementType: currencySettings.measurementType,
        dimensionsUnit: currencySettings.dimensionsUnit,
        fractionalPrecision: currencySettings.fractionalPrecision,
      };

      return ApiService.put('/company/currencySettings', data).then(
        function () {
          Notification.success('Updated localisation settings');

          return SettingsService.getSettings(true);
        },
        function () {
          Notification.error('Failed to update country settings');

          return $q.reject();
        },
      );
    }
  }
})();
