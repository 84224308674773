(function () {
  'use strict';

  angular
    .module('module.settings.retailerProducts')
    .controller('controller.settings.retailerProducts.create', [
      '$state',
      '$timeout',
      'RetailerProductsApiService',
      'RetailerProductsPricingMatricesApiService',
      'dataRetailerPricingAvailable',
      controller,
    ]);

  function controller(
    $state,
    $timeout,
    ApiService,
    PricingMatricesApiService,
    dataRetailerPricingAvailable,
  ) {
    /* jshint validthis: true */
    var vm = this;

    var pricingMatrixChanges = {
      queuedForUpload: [],
      queuedForDelete: [],
    };

    vm.loading = true;
    vm.editMode = false;

    vm.settings = {
      frameJointType: 'Welded',
      tmJointType: 'Welded',
      sashJointType: 'Welded',
      doorSectionJointType: 'Mechanical',
      sashType: 'Stormproof',
      dummyOpeners: 'Optional',
      paintsAllowed: true,
      renames: {},
    };

    vm.pricing = {
      itemPricing: 'manual',
      componentPricing: 'manual',
    };

    vm.componentPricing = {};

    vm.productImage = null;
    vm.manufacturerImage = null;
    vm.currentManufacturerImage = null;
    vm.activeTab = 'profiles';
    vm.uploadedMatrices = [];
    vm.showPricing = false;
    vm.showComponentPricing = false;
    vm.selectedGenericProduct = {};

    vm.onPricingMatrixChanges = onPricingMatrixChanges;
    vm.getRenameKey = getRenameKey;
    vm.save = save;
    vm.getComponentOptions = getComponentOptions;
    vm.onChangeGenericProduct = onChangeGenericProduct;

    ApiService.listGenericProducts().then(function (genericProducts) {
      vm.genericProducts = genericProducts;
      vm.selectedGenericProduct = genericProducts[0];
      updateSelectedProduct();
    });

    function onChangeGenericProduct() {
      vm.loading = true;
      vm.activeTab = 'profiles';

      // use timeout to force dom update for spinner when generic product cached
      $timeout(updateSelectedProduct, 0);
    }

    function updateSelectedProduct() {
      ApiService.getGenericProduct(vm.selectedGenericProduct.id).then(function (genericProduct) {
        vm.selectedProduct = genericProduct;
        vm.currentProductImage = vm.selectedProduct.thumb;
        vm.settings.componentOptions = {};
        vm.settings.componentDefaults = {};
        vm.settings.renames = {};
        vm.settings.range = {};
        vm.settings.sashType = vm.selectedProduct.defaultSashType;
        vm.settings.handleType = vm.selectedProduct.defaultHandleType;
        vm.componentPricing = {};
        // Don't need to wait for the generic products, but it looks better if we do
        vm.showPricing = dataRetailerPricingAvailable && vm.selectedProduct.pricingMethods.length;
        vm.showComponentPricing = dataRetailerPricingAvailable;

        vm.loading = false;
      });
    }

    function save() {
      vm.saving = true;
      var newId;
      ApiService.create(
        vm.name,
        vm.selectedProduct.id,
        vm.settings,
        vm.productImage,
        vm.manufacturerImage,
        vm.pricing,
        vm.componentPricing,
      )
        .then(function (retailerProduct) {
          newId = retailerProduct.id;
          return PricingMatricesApiService.update(
            retailerProduct,
            [], // never any deletions for a new RP
            pricingMatrixChanges.queuedForUpload,
          );
        })
        .then(function () {
          if (vm.closeAfterSave) {
            $state.go('settings.retailerProducts', null, {reload: true});
          } else {
            $state.go(
              'settings.retailerProducts.update',
              {
                id: newId,
                tab: vm.activeTab,
              },
              {reload: true},
            );
          }
        });
    }

    function getRenameKey(hardwareType) {
      if (['master handle', 'slave handle', 'folding handle'].includes(hardwareType)) {
        return 'door handle';
      }
      return hardwareType;
    }

    function onPricingMatrixChanges(changes) {
      pricingMatrixChanges = changes;
      vm.pricing.pricingMethod = changes.pricingMethod;
      if (changes.queuedForUpload.length) {
        vm.pricing.pricingMatrixType = changes.queuedForUpload[0].matrixType;
      }
    }

    function getComponentOptions(componentKey) {
      return vm.selectedProduct.components[componentKey];
    }
  }
})();
