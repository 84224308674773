(function () {
  'use strict';

  angular.module('module.sales').directive('ttRetailerManufacturerProductSettings', directive);

  /**
   * Init directive
   */
  function directive() {
    return {
      restrict: 'A',
      templateUrl: 'templates/sales.retailer.manufacturerProduct.directive.template.html',
      controllerAs: 'productVm',
      scope: {
        retailerSettings: '=',
      },
      bindToController: true,
      controller: ['ProductsService', 'pricingModes', 'CurrencyService', controller],
    };
  }

  function controller(ProductsService, pricingModes, CurrencyService) {
    /* jshint validthis: true */
    var vm = this;

    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.updatePricingMode = updatePricingMode;

    vm.combinationProducts = [];
    CurrencyService.getSettingsAsync().then((data) => {
      vm.currencySymbol = data.currencySymbol;
    });

    var coupledItemProductId = 11;
    ProductsService.listProducts().then(function (products) {
      products.forEach(function (product) {
        if (product.metadata.productType.id === coupledItemProductId) {
          vm.combinationProducts.push(product.id);
        }
      });
      vm.productsReady = true;
    });

    function selectAll() {
      vm.retailerSettings.products.forEach(function (product) {
        product.enabled = true;
      });
    }

    function deselectAll() {
      vm.retailerSettings.products.forEach(function (product) {
        product.enabled = false;
      });
    }

    function updatePricingMode(mode) {
      if (
        vm.retailerSettings.pricingMode === pricingModes.fixed &&
        mode === pricingModes.perProduct
      ) {
        vm.retailerSettings.products.forEach(function (product) {
          product.discount = vm.retailerSettings.discount;
          product.surchargePercentage = vm.retailerSettings.surchargePercentage;
        });
      }

      vm.retailerSettings.pricingMode = mode;
    }
  }
})();
