(function () {
  'use strict';

  angular
    .module('module.termsAndConditions')
    .factory('TermsAndConditionsService', ['$q', 'Notification', 'ApiService', '$state', service]);

  /**
   * @param $q
   * @param Notification
   * @param ApiService
   * @returns {{listTermsandconditions: listTermsandconditions, createTermsConditions: createTermsConditions, updateTermsandconditions: updateTermsandconditions, postTermsandconditions:postTermsandconditions, deleteTermsConditions: deleteTermsConditions, getCurrentVersion: getCurrentVersion}}
   */
  function service($q, Notification, ApiService, $state) {
    return {
      listTermsandconditions: listTermsandconditions,
      createTermsConditions: createTermsConditions,
      updateTermsandconditions: updateTermsandconditions,
      postTermsandconditions: postTermsandconditions,
      deleteTermsConditions: deleteTermsConditions,
      getCurrentVersion: getCurrentVersion,
    };

    /**
     * @returns {$Q}
     */
    function listTermsandconditions() {
      return ApiService.get('/terms-and-conditions').then(function (response) {
        return response.data;
      });
    }

    /**
     * @returns {$Q}
     */
    function getCurrentVersion() {
      return ApiService.get('/terms-and-conditions/current').then(function (response) {
        return response;
      });
    }

    /**
     * TODO: separate into functions for each user class
     *
     * @param {object} terms
     * @returns {$Q}
     */
    function createTermsConditions(terms) {
      return ApiService.post('/terms-and-conditions', terms).then(
        function (response) {
          Notification.success('Created terms and conditions');
          return response.terms;
        },
        function () {
          Notification.error('Failed to create terms and conditions');
          return $q.reject(null);
        },
      );
    }

    /**
     * @param {object} terms
     * @returns {$q}
     */
    function updateTermsandconditions(terms) {
      return ApiService.put('/terms-and-conditions/update', terms).then(
        function (response) {
          Notification.success('Updated terms and conditions');
          return response.data.TermsAndConditions;
        },
        function () {
          Notification.error('Failed to update terms and conditions');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {object} data
     */
    function postTermsandconditions(data) {
      return ApiService.post('/terms-and-conditions/accept', data).then(
        function (response) {
          return response.data;
        },
        function () {
          Notification.error('Failed to update terms and conditions');
          $state.go('authentication.signin');
          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} terms
     * @returns {$q}
     */
    function deleteTermsConditions(terms) {
      return ApiService.delete('/terms-and-conditions/' + terms).then(
        function () {
          Notification.success('Deleted item');
        },
        function () {
          Notification.error('Failed to delete item');
          return $q.reject(null);
        },
      );
    }
  }
})();
